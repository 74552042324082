import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { SserService } from '../../../servicios/sser.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';

import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';
// import {ConfirmationService} from 'primeng/api';
  
  @Component({
    selector: 'app-expediente-fac',
    templateUrl: './expediente-fac.component.html',
    styleUrls: ['./expediente-fac.component.css'],
    providers: [SserService]
  })
  export class ExpedienteFacComponent implements OnInit {

  clienteVis: boolean;
  detalleVis: boolean;
  clientes: Cliente[];
  message: string[];
  selectedValues = [];
  idCiudad:string="";

  constructor(private sser: SserService,) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getExpedientes();
  }

  getExpedientes() {
    let sql = " SELECT * FROM `expedientes` ";

    console.log('sql--> ', sql);
    this.sser.getItems(sql).subscribe(result => {
      if (result && result.length > 0) {
        this.clientes = result;
      }
    });
      

  }
  actualizarLista() {
    this.ngOnInit();
  }
  recuperar(id:string){
    //console.log("id",id);
    let sql = " INSERT INTO `clientes` (`id`, `nombre`, `direccion`, `telefono`, `email`, `idsesion`, `idempresa`, `estado`, `fechaAlta`, `fechaBaja`, `visible`, `recibirNot`, `referencia`, `esAdmin`, `expFacturado`, `esExp`)"+
              " SELECT `id`, `nombre`, `direccion`, `telefono`, `email`, `idsesion`, `idempresa`, `estado`, `fechaAlta`, `fechaBaja`, `visible`, `recibirNot`, `referencia`, `esAdmin`, `expFacturado`, `esExp`"+
              " FROM `expedientes`"+
              " WHERE `id` = '"+id+"'";

    this.sser.getItems(sql).subscribe(result => {
      if (result && result.length > 0) {
        let sql = " DELETE FROM `expedientes` WHERE `id` = '"+id+"'";
        this.sser.getItems(sql).subscribe(result => {
          if (result && result.length > 0) {
            console.log("Se mueve expediente con exito ",id);
          }
        });
      }
    });
  }
}


