import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import {SelectItem} from 'primeng/api';


@Component({
  selector: 'app-crud-cliente',
  templateUrl: './crud-cliente.component.html',
  styleUrls: ['./crud-cliente.component.css'],
  providers: [SempresasService]
})
export class CrudClienteComponent implements OnInit {

  @Input() clienteVis: boolean;
  @Output() cerrarclienteEvento = new EventEmitter();

  nuevoCliente: Cliente = new Cliente();
  empresas: Empresas[];
  conductores: Conductor[];
  selectedValues = [];
  otro = [];
  checked: boolean = true;
  esAdmin:boolean = false;
  esExp:boolean = false;
  idCiudad:string="";
  constructor(private sempresas: SempresasService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.clienteVis = false;
    this.nuevoCliente.recibirNot=1;
    this.nuevoCliente.esAdmin='0';
    this.getEmpresas();
  
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevoCliente.idempresa = idEmpresa;
  }
  new() {
    //if(localStorage.getItem('debug')==='1') console.log('new----->');
    if (this.nuevoCliente.nombre !== '' &&
        this.nuevoCliente.idempresa !== '' &&
        this.nuevoCliente.direccion !== '' &&
        this.nuevoCliente.telefono !== '' &&
        this.nuevoCliente.email !== '' ) {
          if(this.checked) this.nuevoCliente.recibirNot=1;
          else this.nuevoCliente.recibirNot=0;
          this.nuevoCliente.estado = 'activo';
          this.nuevoCliente.fechaAlta = moment().format('YYYY-MM-DD');
          this.nuevoCliente.fechaBaja = '0000-00-00';
          //if(localStorage.getItem('debug')==='1') console.log('new----->'+JSON.stringify(this.nuevoCliente));
          this.sempresas.setCliente(this.nuevoCliente).subscribe();
          this.clienteVis = false;
          this.cerrarclienteEvento.emit();
    }
  }
  handleChange($event){
    this.esAdmin=!this.esAdmin;
    this.nuevoCliente.esAdmin = this.esAdmin?'1':'0';
  }
  handleChangeExp($event){
    //this.esAdmin=!this.esAdmin;
    this.nuevoCliente.esExp = this.esExp?'1':'0';
  }
}
