import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { StarifasService } from '../../../../servicios/starifas.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { Ruta } from '../../../../comun/ruta';
import { Ciudad } from '../../../../comun/ciudad';
import { Preferencia } from '../../../../comun/preferencia';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PreferenciasPeticion } from '../../../../comun/preferencias-peticion';

@Component({
  selector: 'app-detalle-rutas',
  templateUrl: './detalle-rutas.component.html',
  styleUrls: ['./detalle-rutas.component.css'],
  providers: [StarifasService, SempresasService]
})
export class DetalleRutasComponent implements OnInit {
  @Input() ruta: Ruta;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarRutaListEvento = new EventEmitter();

  detalleRutavisible: boolean;
  nuevaRuta: Ruta;
  estados: Estados[];
  ciudades: Ciudad[] = [];

  constructor(private starifas: StarifasService, private sempresas: SempresasService) { }

  ngOnInit() {
    this.nuevaRuta = new Ruta();
    Object.assign(this.nuevaRuta, this.ruta);
    this.getEstadosbd();
    this.getCiudades();
  }
  actualizaRuta() {
    this.nuevaRuta.visible = false;
    this.ruta = this.nuevaRuta;
    //if(localStorage.getItem('debug')==='1') console.log('--->>>' + JSON.stringify(this.nuevaRuta));
     this.starifas.upRuta(this.nuevaRuta).subscribe();
     this.cerrarDetalleVisEvento.emit();
     this.actualizarRutaListEvento.emit();
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.nuevaRuta.estado = estado;
    if (estado === 'inactivo') {
      this.nuevaRuta.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.nuevaRuta.fechaBaja = '0000-00-00';
      this.nuevaRuta.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  asignoCiudad(idciudad: string) {
    this.nuevaRuta.idCiudad = idciudad;
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
}
