import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { Propietario } from '../../comun/propietario';


@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService, SempresasService, SfactService],
})
export class EmpresasComponent implements OnInit {
  @Input() facEmpVis: boolean;
  @Output() cerrarfacEmpEvento = new EventEmitter();


  empresas: Empresas[];
  clientes: Cliente[];
  idCiudad: string = "";
  idCliente: string = "";
  fechaIni: string;
  fechaFin: string;
  fechaUltFact: string;
  empresaFact: Empresas;
  propietario: Propietario;
  message: string[];
  msgs = [];
  aleVis = false;

  /* tslint:disable:triple-equals */
  constructor(private sser: SserService, private sempresas: SempresasService, private sfact: SfactService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.getEmpresas();
    this.getPropietario();

  }

  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
    // if(localStorage.getItem('debug')==='1') console.log('this.fechaIni---->' + this.fechaIni);

    /* if (moment(this.fechaIni).diff(this.fechaFin, 'second') < 0) {
      this.msgs.push({severity: 'warning', summary: 'Se ha interrumpido el proceso.', detail: 'Revise las fechas'});
    }else {
      this.msgs.push({severity: 'info', summary: 'Buscando servicios.', detail: '.....'});
    }
    setTimeout(function() {
      this.msgs = [];
    }.bind(this), 2500); */

    this.aleVis = !this.aleVis;
  }
  getClientes(idempresa: string) {
    this.sempresas.getClientesEmpresa(idempresa).subscribe(result => {
      this.clientes = result;
      if (result && result.length > 0) {

        this.clientes = result;
        this.clientes.unshift({
          nombre: 'todos', id: '1', direccion: '', telefono: '', email: ''
          , conductoresLista: '', idempresa: '', idsesion: '', estado: '', fechaAlta: ''
          , fechaBaja: '', visible: false, recibirNot: 1,'esAdmin':'0','referencia':'',expFacturado:'0'
          , esExp: 'No'
        });

      } else {
        this.clientes = [];
      }
    });
  }
  asignoCliente(idCliente: string) {
    this.idCliente = idCliente;    
  }
  // fechas Ini
  actualizaIniConFechaHoy() {
    this.fechaIni = moment().format('DD/MM/YYYY');
  }
  actualizaIniConFechaUltimaFacturacion() {
    if (this.fechaUltFact == '') {
      this.fechaIni = moment().format('DD/MM/YYYY');
    } else {
      this.fechaIni = moment(this.fechaUltFact).add(1, 'days').format('DD/MM/YYYY');
    }
  }
  actualizaIniConFechaAyer() {
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }

  /* tslint:disable:triple-equals */
  actualizaFinConFechaUltimaFacturacionplus1() {
    if (this.fechaUltFact == '') {
      this.fechaFin = moment().add(1, 'M').format('DD/MM/YYYY');
    } else {
      this.fechaFin = moment(this.fechaUltFact).add(1, 'days').add(1, 'M').format('DD/MM/YYYY');
    }
  }
  actualizaFinConFechaHoy() {
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizafinConFechaManana() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(14, 'days').format('DD/MM/YYYY');
  }
  actualizafinConFecha15d() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(1, 'M').subtract(1, 'days').format('DD/MM/YYYY');
  }

  getPropietario() {
    let propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
        this.propietario = prop;
        //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
        break;
      }
    });
  }
  getEmpresas() {
    
    let cond = JSON.stringify({ 'idCiudad': this.idCiudad });
    
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      for (const bc of this.empresas) {
        // if(localStorage.getItem('debug')==='1') console.log('bc---->' + JSON.stringify(bc));
        if (bc) {
          this.empresaFact = bc;
          this.actualizoEmpresa(bc.id);
          this.getClientes(bc.id);
          break;
        }
      }
    });
  }

  actualizoEmpresa(idEmpresa: any) {
    // if(localStorage.getItem('debug')==='1') console.log('idEmpresa---->' + idEmpresa);
    this.fechaUltFact = '';
    let cif;
    for (const bc of this.empresas) {
      if (bc && bc.id == idEmpresa) {
        cif = bc.cif;
        this.empresaFact = bc;
        this.getClientes(bc.id);
      }
    }
    const datos = JSON.stringify({ cif: cif });
    this.sfact.getCliFact(datos).subscribe(result => {
      for (const res of result) {
        if (res) {
          this.fechaUltFact = res.fechaFin;
        }
      }
    });
    //if(localStorage.getItem('debug')==='1') console.log('------->>>>>>');
    if (this.aleVis == true) this.aleVis = !this.aleVis;
  }
}
