import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ScomercialgestorService } from '../../../servicios/scomercialgestor.service';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SfactService } from '../../../servicios/sfact.service';
import { Comercial } from '../../../comun/comercial';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-crud-comercial',
  templateUrl: './crud-comercial.component.html',
  styleUrls: ['./crud-comercial.component.css'],
  providers: [ScomercialgestorService, ConfirmationService,SempresasService,SfactService]
})
export class CrudComercialComponent implements OnInit {

  @Output() cerrarComercialEvento = new EventEmitter();

  nuevoComercial: Comercial = new Comercial();
  selectedValues = [];
  otro = [];
  checked: boolean = true;
  ok:any;
  idCiudad:string="";
  empresas: Empresas[] = [];
  selectedEmpresas: Empresas[] = [];

  constructor(private sser: ScomercialgestorService,private confirmationService: ConfirmationService,
              private sempresas: SempresasService,private sfact: SfactService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.nuevoComercial.recibirNot=1;
    this.sser.ngOnInit();
    this.getEmpresas();
  }

  getEmpresas() {
    //let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(null).subscribe(result => {
      this.empresas = result;
     });
  }

  new() {

    if (this.nuevoComercial.nombre !== '' &&
        this.nuevoComercial.direccion !== '' &&
        this.nuevoComercial.telefono !== '' &&
        this.nuevoComercial.email !== '' ) {
          if(this.checked) this.nuevoComercial.recibirNot=1;
          else this.nuevoComercial.recibirNot=0;
          this.nuevoComercial.estado = 'activo';
          this.nuevoComercial.fechaAlta = moment().format('YYYY-MM-DD');
          this.nuevoComercial.fechaBaja = '0000-00-00';

         

          this.sser.setComercial(JSON.stringify(this.nuevoComercial)).subscribe(result=>{
            
            let msg ="Se ha creado el comercial";
            
            if (result.error){
              msg = "No se ha creador el comercial debido al siguiente error: "+result.error;
            }else if (result.id){
              
              if (this.selectedEmpresas.length > 0) { 
                let emp = "";
                for (let e of this.selectedEmpresas) {
                   emp += e.id+"-";
                }
                emp = emp.substring(0,emp.length-1);
                let datos =JSON.stringify({ 'idComercial': result.id, 'idEmpresa': emp });
                this.sfact.setEmpXCom(datos).subscribe();
              }

            }

            this.confirmationService.confirm({
              message: msg,
              accept: () => {
                this.cerrarComercialEvento.emit();
              }
            });
       
          });

    }
  }
}
