import { Component, OnInit } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Ciudad } from '../../comun/ciudad';
import {Md5} from 'ts-md5/dist/md5';
import { Global } from '../../comun/global';
import { Servicio } from '../../comun/servicio';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { StarifasService } from '../../servicios/starifas.service';

@Component({
  selector: 'app-ser-up',
  templateUrl: './ser-up.component.html',
  styleUrls: ['./ser-up.component.css'],
  providers: [SserService, SconductoresService, SempresasService,StarifasService]
})
export class SerUpComponent implements OnInit {
  @Input() servicio: Servicio;
  @Output() cerrarSerUpEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();
  estados: Estados[];
  estadoReciente: string;
  estadoActual: string;
  empresas: Empresas[];
  clientes: Cliente[];
  tipoDeServicios: TipoDeServicios[];
  FechaDeRecogida: string;
  nuevoSer: Servicio;
  uploadedFiles: any[] = [];
  fileToUpload: File = null;
  global:Global= new Global();
  nametemp:string;
  img:string="";
  idCiudad:string="";
  ciudades: Ciudad[] = [];

  @ViewChild('search2')
  public searchElementRef2: ElementRef;
  @ViewChild('search3')
  public searchElementRef3: ElementRef;

  public searchControl2: FormControl;
  public searchControl3: FormControl;

  public latitude: number;
  public longitude: number;
  public latitude1: number;
  public longitude1: number;
  public zoom: number;


  constructor(private sser: SserService, private sempresas: SempresasService, private sconductores: SconductoresService,
    private mapsAPILoader: MapsAPILoader, private staf: StarifasService,private ngZone: NgZone) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.img=this.servicio.imgCartel;
    this.nuevoSer = JSON.parse(JSON.stringify(this.servicio));
    if(localStorage.getItem('debug')==='1') console.log(' this.servicio  -------<<<<<<<< ' + JSON.stringify( this.servicio) )
    this.nuevoSer.FechaDeRecogida = moment(this.nuevoSer.FechaDeRecogida, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
    // if(localStorage.getItem('debug')==='1') console.log(' this.servicio.FechaDeRecogida  -------<<<<<<<<<<<< ' +this.nuevoSer.FechaDeRecogida )
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    this.latitude1 = 39.8282;
    this.longitude1 = -98.5795;
    this.getCiudades();
    this.getEmpresas();
    this.getCliente2empresa(this.servicio.idEmpresa);
    this.getTipoDeServicios();

    this.searchControl2 = new FormControl();
    this.searchControl3 = new FormControl();
    this.setCurrentPosition();

    this.mapsAPILoader.load().then(() => {
      const autocomplete2 = new google.maps.places.Autocomplete(this.searchElementRef2.nativeElement, {
        types: ['address']
      });

      const autocomplete3 = new google.maps.places.Autocomplete(this.searchElementRef3.nativeElement, {
        types: ['address']
      });

      autocomplete2.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place2: google.maps.places.PlaceResult = autocomplete2.getPlace();
          // formulario
          this.nuevoSer.Origen = place2.formatted_address;

          // verify result
          if (place2.geometry === undefined || place2.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place2.geometry.location.lat();
          this.longitude = place2.geometry.location.lng();
          this.zoom = 12;
        });
      });
      autocomplete3.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place3: google.maps.places.PlaceResult = autocomplete3.getPlace();
          // formulario
          this.nuevoSer.Destino = place3.formatted_address;
          // verify result
          if (place3.geometry === undefined || place3.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude1 = place3.geometry.location.lat();
          this.longitude1 = place3.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  asignoCiudad(idciudad: string) {
    this.nuevoSer.idCiudad = idciudad;
    
    let c = this.ciudades.filter(item => item.id == idciudad);
    if (c && c.length > 0) {
      this.nuevoSer.Ciudad = c[0].nombre;
    }
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      });
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  getClientes() {
    this.sempresas.getClientes().subscribe(result => {
      this.clientes = result;
    });
  }
  getCliente2empresa(empresaId: string) {
    this.sempresas.getCliente2empresa(empresaId).subscribe(result => {
      this.clientes = result;

    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevoSer.idEmpresa = idEmpresa;
    //this.nuevoSer.idConductor = "";
    this.getCliente2empresa(idEmpresa);
    this.empresas.forEach((item, index) => {
      if (item.id == idEmpresa) {
        this.nuevoSer.Empresa = item.nombre;
      }
    });
  }
  asignoCliente(idCliente: string) {
    this.nuevoSer.idCliente = idCliente;
    this.clientes.forEach((item, index) => {
      if (item.id == idCliente) {
        this.nuevoSer.cliente = item.nombre;
      }
    });
  }
  asignarTipoDeServicio(idTipoDeServicio: string) {
    this.nuevoSer.idTipoDeServicio = idTipoDeServicio;
    this.tipoDeServicios.forEach((item, index) => {
      if (item.id == idTipoDeServicio) {
        this.nuevoSer.TipoDeServicio = item.nombre;
      }
    });
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.latitude1 = position.coords.latitude;
        this.longitude1 = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  up() {

    console.log('servicio --->', this.servicio);
    console.log('nuevoSer ---->', this.nuevoSer);

    //if(localStorage.getItem('debug')==='1') console.log(' this.servicio  ------->>>> ' +JSON.stringify(this.servicio ));

    if (this.fileToUpload) {
      // se renombra fichero y directorio, para que no tenga espacios
      var re = /\s/g;
      var name = this.fileToUpload.name.replace(re, '_');
      //
      var subdir = this.global.DIRBASE_IMG;
      //
      var empresa = this.nuevoSer.Empresa;
      let dirE = empresa;
      var re = /\s/g; 
      dirE = dirE.replace(re, '_');
      var re = /\./g; 
      dirE = dirE.replace(re, '_');
      var re = /\,/g; 
      dirE = dirE.replace(re, '_');
      if(dirE.charAt(dirE.length-1)=='_') dirE=dirE.substring(0,dirE.length-1);
      empresa = dirE+"/";
      this.nametemp = Md5.hashStr(moment().millisecond().toString()).toString();
      this.nuevoSer.imgCartel = this.global.SERVIDOR_URL + this.global.DIRBASE_IMG + empresa +this.nametemp +name;
      this.nuevoSer.cartel="Imagen adjunta "+name;
    }
    this.servicio = JSON.parse(JSON.stringify(this.nuevoSer, this.replacer));
    this.servicio.FechaDeRecogida = moment(this.servicio.FechaDeRecogida, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).format('YYYY-MM-DD HH:mm:ss')
    if (this.fileToUpload) this.uploadFileToActivity();
    this.sser.setServicio(this.servicio).subscribe();
    this.servicio.visible2 = false;
    this.cerrarSerUpEvento.emit();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if(this.servicio.imgCartel){
      this.img=this.fileToUpload.name;
    }
  }
  uploadFileToActivity() {
    this.postFile(this.fileToUpload);
  }

  postFile(fileToUpload: File) {
    var empresa = this.nuevoSer.Empresa;
    let dirE = empresa;
    var re = /\s/g;
    dirE = dirE.replace(re, '_');
    var re = /\./g;
    dirE = dirE.replace(re, '_');
    var re = /\,/g;
    dirE = dirE.replace(re, '_');
    if (dirE.charAt(dirE.length - 1) == '_') dirE = dirE.substring(0, dirE.length - 1);
    empresa = dirE + "/";
    this.sser.envioFile(fileToUpload, empresa, this.nametemp).subscribe(
      res => {
        if(localStorage.getItem('debug')==='1') console.log('res=' + JSON.stringify(res));
      });
  }

}