
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';

import { Global } from '../comun/global';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from "@angular/common/http";


import { SserService } from "./sser.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
  })
};

@Injectable()
export class AuthService {

  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  id: string;
  sesiones: Sesion[];
  data: any;
  global: Global = new Global();

  constructor(private http: HttpClient, private sser: SserService, private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });

  }

  login(user: string, password: string) {

    const hash = Md5.hashStr(password);
    this.sesion.usuario = user;
    this.sesion.hash = hash.toString();
    console.log('this.sesion.hash->',this.sesion.hash);
    //console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionS.php?sesion='
    //+ (JSON.stringify(this.sesion)));

    return this.http.post(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionS.php?sesion='
      + encodeURIComponent(JSON.stringify(this.sesion)), httpOptions).pipe(
        map(result => {
          this.data = result[0];
          if (this.data && this.data.id !== 'NOK') {
            this.sesion = this.data;

            localStorage.setItem('username', user);
            localStorage.setItem('tipo', this.sesion.tipo);
            localStorage.setItem('tkn', result[0].tkn);
            localStorage.setItem('debug', result[0].semilla);
            localStorage.setItem('hoy', moment().format('DD/MM/YYYY'));

            
            if(localStorage.getItem('debug')==='1') console.log('::::::::::::: SUSCRIBIR :::::::::::::::::::::');
            if (!this.data.sub) {
              let tmp = localStorage.getItem('Tmpsub');
              if (tmp) {
                localStorage.setItem('sub', tmp);
                let a = { usuario: user };
                this.sser.addPushSubscriber(JSON.stringify(a), tmp).subscribe();
                if(localStorage.getItem('debug')==='1') console.log('::::::::::::: guardo sus :::::::::::::::::::::');
              }
            } else {
              if(localStorage.getItem('debug')==='1') console.log('::::::::::::: recupero sus :::::::::::::::::::::');
            }
            if(localStorage.getItem('debug')==='1') console.log('::::::::::::: END SUSCRIBIR :::::::::::::::::::::');






          } else return 'NOK';
          return this.data.id;
        }));

  }

  logout(): any {
    localStorage.removeItem('username');
    localStorage.removeItem('id');
    localStorage.removeItem('tipo');
  }
  getUser(): any {
    return localStorage.getItem('username');
  }
  getId(): any {
    return localStorage.getItem('id');
  }
  getTipo(): any {
    return localStorage.getItem('tipo');
  }
  isLoggedIn(): boolean {
    return this.getUser() !== null;
  }


  getPreguntasControl(): Observable<any> {
    //if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/mantUsuario/preguntasControlS.php');

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantUsuario/preguntasControlS.php?tkn={"tkn":"55e562bfee2bde4f9e71b8885eb5e303"}');
  }


  sendEmail(datos: string): Observable<any> {
    const tkn1 = { 'tkn1': '55e562bfee2bde4f9e71b8885eb5e303' };
    //if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/s_comun/mail.php?tkn1=' + (JSON.stringify(tkn1))+ '&datos=' + datos);

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/mail.php?tkn1=' + encodeURIComponent(JSON.stringify(tkn1))
      + '&datos=' + encodeURIComponent(datos));
  }
  setUrlTkn(correo: string): Observable<any> {
    const tkn1 = { 'tkn1': '55e562bfee2bde4f9e71b8885eb5e303', "correo": correo, "fecha": moment().format('YYYY-MM-DD HH:mm:ss') };
    //if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/s_comun/urlTknI.php?tkn1=' + encodeURIComponent(JSON.stringify(tkn1)));
    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/urlTknI.php?tkn1=' + encodeURIComponent(JSON.stringify(tkn1)));
  }
  getUrlTkn(correo: string, tkn: string): Observable<any> {
    const tkn1 = { 'tkn1': '55e562bfee2bde4f9e71b8885eb5e303', "correo": correo, "tkn": tkn };
    //if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/s_comun/recCont.php?tkn=' + (JSON.stringify(tkn1)));
    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/recCont.php?tkn=' + encodeURIComponent(JSON.stringify(tkn1)));
  }
  setchnpswusr(user: string, texto: string): Observable<any> {
    const tkn1 = { 'tkn1': '55e562bfee2bde4f9e71b8885eb5e303', "user": user, "texto": texto };
    if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/s_comun/chnpswusr.php?tkn1=' + (JSON.stringify(tkn1)));
    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/chnpswusr.php?tkn1=' + encodeURIComponent(JSON.stringify(tkn1)));
  }
}
export const AUTH_PROVIDERS: Array<any> = [
  { provide: AuthService, useClass: AuthService }
];
