import { Component } from '@angular/core';
import { UsuariosService } from '../servicios/usuarios.service';
import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Estados } from '../comun/estados';
import { Tkn } from '../comun/tkn';
import { OnInit,OnDestroy,ViewEncapsulation } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '../servicios/auth.service';
import { StarifasService } from '../servicios/starifas.service';
import { Ciudad } from '../comun/ciudad';
import { SserService } from '../servicios/sser.service';
import { Global } from '../comun/global';
import { Propietario } from '../comun/propietario';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [AuthService,UsuariosService,SserService,StarifasService]
})
export class HomeComponent implements OnInit, OnDestroy {
  @Output() actUrgEvento = new EventEmitter();
  @Output() desUrgEvento = new EventEmitter();
  @Output() actCronserEvento = new EventEmitter();
  @Output() desCronserEvento = new EventEmitter();
  
  title = 'Taxi';
  conductores: Conductor[];
  estados: Estados[];
  intervalId: any;
  color:string="green";
  global: Global = new Global();
  nuevoServicioVis: boolean;
  planServicioVis: boolean;
  listServicioVis: boolean;
  empresaListVis: boolean;
  agenServicioVis: boolean;
  urgentesVis:boolean;
  cronserVis:boolean;
  cargasVis:boolean;
  mantCargasVis:boolean;
  empresaVis: boolean;
  mantVis: boolean;
  manDis:boolean = false;
  carDis:boolean = false;
  aleDis:boolean = false;
  serVis: boolean;
  cargasListVis:boolean;
  factVis:boolean;
  servicios: Servicio[];
  servicio: Servicio;
  headerConfig: any;
  events: any[];
  tkns: Tkn[];
  tkn: Tkn;
  aleVis:any;
  ciudades: Ciudad[] = [];
  propietario: Propietario;
  idCiudad: string ="";
 
// inyecto ServiciosTaxi
  constructor(private starifas: StarifasService,public authService: AuthService , private sser: SserService,private usuariosService: UsuariosService ) {
    this.nuevoServicioVis = false;
    this.planServicioVis  = true;
    this.listServicioVis  = false;
    this.agenServicioVis = false;
    this.mantVis = false;
  }
  ngOnDestroy() {
    this.desactivar();
  }
  ngOnInit(): void {
    this.getPropietario();
  }
  funcRes(){
    let idCiudad = localStorage.getItem("IdCiudad");
    if(idCiudad && idCiudad=='0'){
      console.log('oooo----------------->>>>>>  Funcionalidad restringida');
      this.manDis = true;
      this.carDis = true;
      this.aleDis = true;
    }else{
      this.manDis = false;
      this.carDis = false;
      this.aleDis = false;
    }
  }
  activar(){
 // funcionalidad restringida
    this.funcRes();
    if(localStorage.getItem('debug')==='1') console.log('cron:: activados Urgentes ');
    this.urgentesVis=true;
    if(localStorage.getItem('debug')==='1') console.log('cron:: activados Tknact ');
    this.cronserVis=true;
    if(localStorage.getItem('debug')==='1') console.log("cron:: activadas Alarmas");
    this.getNotificaciones();    
    this.intervalId = setInterval(() => this.getNotificaciones(), 1000 * this.global.TIEMPO_ESPERA_ALERTAS_SEG * 1);
  }
  desactivar(){
    if(localStorage.getItem('debug')==='1') console.log('cron:: desactivados Urgentes ');
    this.urgentesVis=false;
    if(localStorage.getItem('debug')==='1') console.log('cron:: desactivados Tknact ');
    this.cronserVis=false;
    if(localStorage.getItem('debug')==='1') console.log("cron:: desactivadas Alarmas");
    clearInterval(this.intervalId);
  }
  logout(): boolean {
    this.authService.logout();
    return true;
  }
  getNotificaciones() {
    this.getAlarmas();
  }
  getAlarmas(){
    var email = localStorage.getItem('username');
    const config = JSON.stringify({ 'tabla': 'alertas', 'conEstado': '0' });
    const filtro = JSON.stringify({ 'filtro': 'para', 'valor': email, 'traza': '0' });
   const filtro1 = JSON.stringify({ 'filtro1': 'estado', 'valor1': 'Activo',"operator1":"AND"});
   const filtro2 = null;//JSON.stringify({ 'filtro2': 'para', 'valor2': 'Todos',"operator2":"OR"});

    this.sser.lista(config, null, filtro,filtro1,filtro2).subscribe(result => {
      var alertas = result;
      if(alertas && alertas.length>0) this.color="red";
      else this.color="green";
      
    });

  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      /** esta funcionalidad esta en periodo de prueba */
      this.ciudades.push({estado:"activo",fechaAlta:"0000-00-00",fechaBaja:"0000-00-00",
        id:'0',latitud:'40.416775',longitud:'-3.70379',nombre:"Todas (restringido)",visible:false,
        zoom:'9'});
      /** a falta de ver resultados */

    });
  }
  getPropietario() {
    this.sser.getPropietario().subscribe(result => {
      for (let res of result) {
        this.propietario = res;
        break;
      }
      localStorage.setItem("IdCiudad",this.propietario.idCiudad);
      this.idCiudad = this.propietario.idCiudad;
      this.getCiudades()
      //tareas cron
      this.activar();

    });
  }
  actualizaLista(id: any) {
    localStorage.setItem("IdCiudad",id);
    this.serVis = false;
    this.mantVis=false;
    this.factVis=false;
    this.cargasVis=false;
    this.aleVis=false;
    this.planServicioVis = false;
    console.log('--------------------> fuera vistas');
    this.funcRes();
  }
  cargaIA(){
      // Abre una nueva pestaña y carga la URL
      window.open('https://peticion.luxucar.es/home?usu=administrador@luxucar.es&tkn=64940e46eae6c68025b58b244643456f&l=1&t=1&v=0', '_blank');

  }
}
