import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { Categoria } from '../../../comun/categoria';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-categorias',
  templateUrl: './crud-categorias.component.html',
  styleUrls: ['./crud-categorias.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SconductoresService]
})
export class CrudCategoriasComponent implements OnInit {
  @Input() categoriasVis: boolean;
  @Output() cerrarCategoriasEvento = new EventEmitter();

  categoriaNuevo: Categoria = new Categoria();



  constructor(private sconductores: SconductoresService) { }

  ngOnInit() {
    this.categoriasVis = false;
  }
  new() {

    this.categoriaNuevo.visible = false;

    this.sconductores.setCategoria(JSON.stringify(this.categoriaNuevo)).subscribe();
    this.cerrarCategoriasEvento.emit();
  }
}