import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
// import {HttpModule} from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { Propietario } from '../../comun/propietario';

@Component({
  selector: 'app-emp-a-fact',
  templateUrl: './emp-a-fact.component.html',
  styleUrls: ['./emp-a-fact.component.css'],
  providers: [SserService, SempresasService, SfactService]
})
export class EmpAFactComponent implements OnInit {
  
  @Output() cerrarFacEmpFechEvento = new EventEmitter();
  fechaIni: string;
  fechaFin: string;
  msgs = [];
  aleVis= false;


  constructor() { }

  ngOnInit() {
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizafinConFecha15d() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(14, 'days').format('DD/MM/YYYY');
  }
  actualizafinConFecha1m() {
    this.fechaFin = moment(this.fechaIni , 'DD/MM/YYYY').add(1, 'M').subtract(1, 'days').format('DD/MM/YYYY');
  }
  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
   // if(localStorage.getItem('debug')==='1') console.log('this.fechaIni---->' + this.fechaIni);

    /* if (moment(this.fechaIni).diff(this.fechaFin, 'second') < 0) {
      this.msgs.push({severity: 'warning', summary: 'Se ha interrumpido el proceso.', detail: 'Revise las fechas'});
    }else {
      this.msgs.push({severity: 'info', summary: 'Buscando servicios.', detail: '.....'});
    }
    setTimeout(function() {
      this.msgs = [];
    }.bind(this), 2500); */

    this.aleVis = !this.aleVis;
  }
}
