import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConductorOcupado } from '../../../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../../servicios/sser.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../../../comun/servicio';
import { Global } from '../../../../comun/global';
import { Empresas } from '../../../../comun/empresas';
import { Cliente } from '../../../../comun/cliente';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Md5 } from 'ts-md5/dist/md5';
import { Ciudad } from '../../../../comun/ciudad';
import { StarifasService } from '../../../../servicios/starifas.service';
import { SstService } from '../../../../servicios/sst.service';
import { ConfirmationService } from 'primeng/api';
import { Propietario } from '../../../../comun/propietario';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Documento } from '../../../../comun/documento';


@Component({
  selector: 'app-res-pres',
  templateUrl: './res-pres.component.html',
  styleUrls: ['./res-pres.component.css'],
  providers: [SserService, SconductoresService, SempresasService, StarifasService, SstService, SstService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class ResPresComponent implements OnInit {
  @Input() documentos:Documento;
  @Input() columnas:any;

  @Output() cerrarBusPresEvento = new EventEmitter();

  verLog: boolean = true;

  empresas: Empresas[] = [];
  selEmpresa: Empresas;
  numeroPres:string;
 
  deleDoc:Documento;

  docs:Documento[] = [];
  global: Global = new Global();
  fd: FormData = new FormData();
  tabla:any;


  constructor(private sser: SserService, private confirmationService: ConfirmationService, private sscond: SconductoresService) { 
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    //this.fechaFin = moment().format('DD/MM/YYYY');
  
  }
  delDoc(doc:Documento){
    if (this.verLog) console.log('doc a borrar-->', doc);
    this.deleDoc = doc;
    let msg='¿Esta seguro que quiere eliminarlo,una vez realizado no podra recuperarlo?';
    this.Ok(msg,true);
  }
  delDocFile() {
    let directorio = '';
    let fichero = '';


    //URL
    let u = this.deleDoc.url.split(this.global.SERVIDOR_URL);
    if(u && u.length>1){
      if (this.verLog) console.log('url a borrar-->', u[1]); 
      
      let a =  u[1].split('/');
      fichero = a[5];    
      if (this.verLog) console.log('fichero-->',fichero);

      let b = u[1].split(fichero);
      directorio = b[0];
      if (this.verLog) console.log('directorio-->',directorio);
    }
    this.fd = new FormData();
    this.fd.append('dir', '../..'+directorio);
    this.fd.append('namefile', fichero);

    this.sser.borrarFile_nd(this.fd).subscribe(res => {
      if (this.verLog) console.log('fborrarFile_nd res-->',res);
      this.delDocBBDD();
    });
  }

  delDocBBDD() {    
    let sql = "DELETE FROM `documentos` WHERE`id`='"+this.deleDoc.id+"'";
    this.sser.getItems(sql).subscribe(result => {
      this.cerrarBusPresEvento.emit() ;
    });
  }
  Nok(msg: string) {
    this.confirmationService.confirm({
      key: "Nok",
      message: msg,
      accept: () => {
      }
    });
  }
  Ok(msg: string, fin: boolean) {
    this.confirmationService.confirm({
      key: "Ok",
      message: msg,
      accept: () => {
        (fin) ? this.delDocFile():'';
        
      },
      reject: () => {

      }
    });
  }

}
