import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { Global } from '../../comun/global';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { Md5 } from 'ts-md5/dist/md5';
import { Ciudad } from '../../comun/ciudad';
import { StarifasService } from '../../servicios/starifas.service';
import { SstService } from '../../servicios/sst.service';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-ser-new',
  templateUrl: './ser-new.component.html',
  styleUrls: ['./ser-new.component.css'],
  providers: [SserService, SconductoresService, SempresasService, StarifasService, SstService, SstService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class SerNewComponent implements OnInit {
  @Input() serNewVis: boolean;
  @Output() cerrarSerNewEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();
  nuevoSer: Servicio;
  empresas: Empresas[];
  clientes: Cliente[];
  tipoDeServicios: TipoDeServicios[];
  fechaRecogida: string;
  extbool: boolean = false;
  global: Global = new Global();
  numeroRepeticiones: number = 1;
  checked: any;
  uploadedFiles: any[] = [];
  fileToUpload: File = null;
  nametemp: string;
  idCiudad: string = "";
  grabar: boolean = false;
  ok:any;

  colordestino:string="orange";
  colororigen:string="orange";
  colorpasajero:string="orange";
  colorcartel:string="orange";
  colorvuelo:string="orange";



  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('search1')
  public searchElementRef1: ElementRef;

  public searchControl: FormControl;
  public searchControl1: FormControl;

  public latitude: number;
  public longitude: number;
  public latitude1: number;
  public longitude1: number;
  public zoom: number;
  ciudades: Ciudad[] = [];

  isButtonDisabled: boolean = false;
  num_envi=0;


  constructor(private sser: SserService, private sempresas: SempresasService, private sconductores: SconductoresService,
    private mapsAPILoader: MapsAPILoader, private staf: StarifasService, private ngZone: NgZone,
    private sst: SstService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getCiudades();
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    this.latitude1 = 39.8282;
    this.longitude1 = -98.5795;
    this.serNewVis = false;
    this.nuevoSer = new Servicio();
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.nuevoSer.FechaDePeticion = moment().format('DD/MM/YYYY HH:mm');
    //
    this.nuevoSer.estado = 'En petición';

    this.getEmpresas();
    this.getClientes();
    this.getTipoDeServicios();
    this.searchControl = new FormControl();
    this.searchControl1 = new FormControl();
    this.setCurrentPosition();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });

      const autocomplete1 = new google.maps.places.Autocomplete(this.searchElementRef1.nativeElement, {
        types: ['address']
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // formulario
          this.nuevoSer.Origen = place.formatted_address;

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
      autocomplete1.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place1: google.maps.places.PlaceResult = autocomplete1.getPlace();
          // formulario
          this.nuevoSer.Destino = place1.formatted_address;
          // verify result
          if (place1.geometry === undefined || place1.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude1 = place1.geometry.location.lat();
          this.longitude1 = place1.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  ext() {

  }

  starRecord(boton: string) {
    let idioma = 'es_ES';
    this.grabar = !this.grabar;
    if (this.grabar) {
      console.log("start" + boton);
      if (boton === "origen")   this.colororigen="red";
      if (boton === "destino")  this.colordestino ="red";
      if (boton === "pasajero") this.colorpasajero="red";
      if (boton === "cartel")   this.colorcartel="red";
      if (boton === "vuelo")   this.colorvuelo="red";

      this.sst.startRecord(idioma).subscribe(res => {
        if (boton === "origen")  this.nuevoSer.Origen = res;
        if (boton === "destino") this.nuevoSer.Destino = res;
        if (boton === "pasajero")this.nuevoSer.pasajero = res;
        if (boton === "cartel")  this.nuevoSer.cartel = res;
        if (boton === "vuelo")   this.nuevoSer.vuelo = res;
      });
    } else {
      console.log("stop");
      this.sst.stopRecord();
      this.colordestino ="orange";
      this.colororigen="orange";
      this.colorpasajero="orange";
      this.colorcartel="orange";
      this.colorvuelo="orange";
    }
  }

  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getEmpresas() {
    let cond = JSON.stringify({ 'idCiudad': this.idCiudad });
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  getClientes() {
    this.sempresas.getClientes().subscribe(result => {
      this.clientes = result;
      for (const cli of result) {
        this.nuevoSer.idCliente = '';
        this.nuevoSer.cliente = cli.nombre;
      }
    });
  }
  getCliente2empresa(empresaId: string) {
    this.sempresas.getCliente2empresa(empresaId).subscribe(result => {
      this.clientes = result;
      for (const cli of result) {
        this.nuevoSer.idCliente = cli.id;
        this.nuevoSer.cliente = cli.nombre;
      }
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevoSer.idEmpresa = idEmpresa;
    this.getCliente2empresa(idEmpresa);
    this.empresas.forEach((item, index) => {
      if (item.id == idEmpresa) {
        this.nuevoSer.Empresa = item.nombre;
      }
    });
  }
  asignoCliente(idCliente: string) {
    this.nuevoSer.idCliente = idCliente;
    this.clientes.forEach((item, index) => {
      if (item.id == idCliente) {
        this.nuevoSer.cliente = item.nombre;
      }
    });
  }
  asignarTipoDeServicio(idTipoDeServicio: string) {
    this.nuevoSer.idTipoDeServicio = idTipoDeServicio;
    this.tipoDeServicios.forEach((item, index) => {
      if (item.id == idTipoDeServicio) {
        this.nuevoSer.TipoDeServicio = item.nombre;
      }
    });
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.latitude1 = position.coords.latitude;
        this.longitude1 = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  new() {
    this.num_envi++;
    console.log('----- Pulso enviar --->'+this.num_envi);
    if (this.fechaRecogida && this.nuevoSer.Origen && this.nuevoSer.Destino) {
      this.isButtonDisabled = true;
      this.nuevoSer.FechaDeRecogida = moment(this.fechaRecogida, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      this.nuevoSer.FechaDePeticion = moment().format('YYYY-MM-DD HH:mm:ss');
      if (this.extbool) this.nuevoSer.estado = this.global.ESTADO_ADMINISTRANDO;
      let cont = 1;
      if (this.fileToUpload) {
        // se renombra fichero y directorio, para que no tenga espacios
        var re = /\s/g;
        var name = this.fileToUpload.name.replace(re, '_');
        //
        var subdir = this.global.DIRBASE_IMG;
        //
        var empresa = this.nuevoSer.Empresa;
        let dirE = empresa;
        var re = /\s/g;
        dirE = dirE.replace(re, '_');
        var re = /\./g;
        dirE = dirE.replace(re, '_');
        var re = /\,/g;
        dirE = dirE.replace(re, '_');
        if (dirE.charAt(dirE.length - 1) == '_') dirE = dirE.substring(0, dirE.length - 1);
        empresa = dirE + "/";
        this.nametemp = Md5.hashStr(moment().millisecond().toString()).toString();
        this.nuevoSer.imgCartel = this.global.SERVIDOR_URL+ this.global.DIRBASE_IMG + empresa + this.nametemp + name;
        this.nuevoSer.cartel = "Imagen adjunta " + name;
      }
      this.nuevoSer.idCiudad = this.idCiudad;
      let c = this.ciudades.filter(item => item.id == this.idCiudad);
      if (c && c.length > 0) {
        this.nuevoSer.Ciudad = c[0].nombre;
      }
      
      if (this.fileToUpload) this.uploadFileToActivity();

      //
        if(this.numeroRepeticiones>1){
          while (cont <= this.numeroRepeticiones) {
            this.sser.newServicio(this.nuevoSer).subscribe();
            this.wait(500);
            cont++;
          }
          this.cerrarSerNewEvento.emit();
        }else{
        this.sser.newServicio(this.nuevoSer).subscribe(res=>{
          let msg ="No se ha creado el servicio.";
          if(res && res.error) msg+= " Error  "+res.error;
          if(res && res.id) msg= "Se ha creado el servicio con el id "+res.id;

          this.confirmationService.confirm({
            message: msg,
            accept: () => {
              this.cerrarSerNewEvento.emit();
            }
          });
        });
      }
      
    }
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  uploadFileToActivity() {
    this.postFile(this.fileToUpload);
  }

  postFile(fileToUpload: File) {
    var empresa = this.nuevoSer.Empresa;
    let dirE = empresa;
    var re = /\s/g;
    dirE = dirE.replace(re, '_');
    var re = /\./g;
    dirE = dirE.replace(re, '_');
    var re = /\,/g;
    dirE = dirE.replace(re, '_');
    if (dirE.charAt(dirE.length - 1) == '_') dirE = dirE.substring(0, dirE.length - 1);
    empresa = dirE + "/";
    this.sser.envioFile(fileToUpload, empresa, this.nametemp).subscribe(
      res => {
        if(localStorage.getItem('debug')==='1')  console.log('res=' + JSON.stringify(res));
      });
  }
}
