import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { TipoSer2prefS } from '../../../../comun/tipo-ser2pref-s';
import { Conductor } from '../../../../comun/conductor';
import { Preferencia } from '../../../../comun/preferencia';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PreferenciaCb } from '../../../../comun/preferencia-cb';
import { Categoria } from '../../../../comun/categoria';

@Component({
  selector: 'app-detalle-categorias-list',
  templateUrl: './detalle-categorias-list.component.html',
  styleUrls: ['./detalle-categorias-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SconductoresService]
})
export class DetalleCategoriasListComponent implements OnInit {
  @Input() categoria: Categoria;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarCategoriaListEvento = new EventEmitter();

  detalleCategoriavisible: boolean;
  categoriaNuevo: Preferencia;

  preferencias: PreferenciaCb[];
  tipoSer2prefSsSel: TipoSer2prefS[];
  selVal: string[]= [];

  constructor(private sconductores: SconductoresService, private sempresas: SempresasService) { }

  ngOnInit() {
    this.categoriaNuevo = JSON.parse(JSON.stringify(this.categoria,this.replacer));

    this.getTipoSer2prefS();
    this.getPreferencias();
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  actualizaCategoria() {
    this.categoriaNuevo.visible = false;
    this.categoria = this.categoriaNuevo;

    // actualizo preferencias
    // borro actuales
    const ts1: TipoSer2prefS = new TipoSer2prefS();
    ts1.idCategoria = this.categoria.id;
    this.sconductores.borraTipoSer2pref(ts1).subscribe();
    // inserto nuevas
    for (const sel of this.selVal) {
      const ts: TipoSer2prefS = new TipoSer2prefS();
      ts.idPreferencia = sel.toString();
      ts.idCategoria = this.categoria.id;
      this.sconductores.upTipoSer2pref(ts).subscribe();
    }
    // actualizo categoria
     this.sconductores.upCategoria(JSON.stringify(this.categoriaNuevo)).subscribe();
     this.cerrarDetalleVisEvento.emit();
     this.actualizarCategoriaListEvento.emit();
  }



  // preferencias seleccionadas
  getTipoSer2prefS() {
    const tipoSer2pref: TipoSer2prefS = new TipoSer2prefS();
    tipoSer2pref.idCategoria = this.categoriaNuevo.id;
    this.sconductores.getTipoSer2pref(tipoSer2pref).subscribe((result => {
      this.tipoSer2prefSsSel = result;
      for (const ts of this.tipoSer2prefSsSel) {
        this.selVal.push(ts.idPreferencia.toString());
      }
       //if(localStorage.getItem('debug')==='1') console.log('   Actualizo ------------------- this.selVal====' + JSON.stringify(this.selVal) );
    }));
  }
  // todas las preferencias
  getPreferencias() {
    this.sempresas.getPreferenciasR().subscribe((result => {
      this.preferencias = result;
       //if(localStorage.getItem('debug')==='1') console.log('   Actualizo ------------------- this.preferencias====' + JSON.stringify(this.preferencias) );
    }));

  }
}
