import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Global } from '../comun/global';
import { HttpClient } from "@angular/common/http";



@Injectable()
export class SempresasService {

  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  // Servicios para Clientes----------------------------------------------------------------------------------------------
  // consulta
  getCliente2empresa(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const cliente: ClientePeticion = new ClientePeticion();
    cliente.idempresa = id;

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

  }
  getExpedientes2empresa(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const cliente: ClientePeticion = new ClientePeticion();
    cliente.idempresa = id;

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/expedientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/expedientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

  }
  getClientes(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + (JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  getExpedientes(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    console.log("tkn",this.tkn.tkn);
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/expedientesS.php?tkn=' + (JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/expedientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  getClientesEmpresa(idempresa: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const cliente = new ClientePeticion();
    cliente.idempresa = idempresa;

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + (JSON.stringify(this.tkn)
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente))));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

  }
  borraCliente(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const cliente = new ClientePeticion();
    cliente.id = id;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesD.php?tkn=' + (JSON.stringify(this.tkn)
      + '&cliente=' + (JSON.stringify(cliente))));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));

  }
  getEstadosbd(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/estadosbdS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/estadosbdS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }
  setCliente(cliente: Cliente): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + (JSON.stringify(cliente)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));
  }


  setconductores2Cliente(datos: string): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/conductores2ClienteI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/conductores2ClienteI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }

  getconductores2Cliente(idCliente: string): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/conductores2ClienteS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + (idCliente));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/conductores2ClienteS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(idCliente));
  }

  upCliente(cliente: Cliente): Observable<any> {
    if (localStorage.getItem('debug') === '1')console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + (JSON.stringify(cliente)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/clientesU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&cliente=' + encodeURIComponent(JSON.stringify(cliente)));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para empresas----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getEmpresas(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (datos) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + '&empresa=' + datos);

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + '&empresa=' + encodeURIComponent(datos));
    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
    }
  }
  getEmpresa(idEmpresa: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)) +
      '&empresa=' + idEmpresa);

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)) +
      '&empresa=' + idEmpresa);
  }
  setEmpresa(empresa: Empresas): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&empresa=' + (JSON.stringify(empresa)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&empresa=' + encodeURIComponent(JSON.stringify(empresa)));
  }
  updateEmpresa(empresa: Empresas): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&empresa=' + encodeURIComponent(JSON.stringify(empresa)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&empresa=' + encodeURIComponent(JSON.stringify(empresa)));
  }
  borraEmpresa(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const empresa = new EmpresaPeticion();
    empresa.id = id;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasD.php?tkn=' + (JSON.stringify(this.tkn)
      + '&empresa=' + (JSON.stringify(empresa))));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empresasD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&empresa=' + encodeURIComponent(JSON.stringify(empresa)));

  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para preferencias----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getPreferencias(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }
  getPreferenciasR(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasSR.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasSR.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }
  setPreferencia(preferencia: Preferencia): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&preferencia=' + (JSON.stringify(preferencia)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&preferencia=' + encodeURIComponent(JSON.stringify(preferencia)));
  }
  updatePreferencia(preferencia: Preferencia): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&preferencia=' + encodeURIComponent(JSON.stringify(preferencia)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&preferencia=' + encodeURIComponent(JSON.stringify(preferencia)));
  }
  borraPreferencia(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const preferencia = new PreferenciasPeticion();
    preferencia.id = id;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasD.php?tkn=' + (JSON.stringify(this.tkn)
      + '&preferencia=' + (JSON.stringify(preferencia))));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/preferenciasD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&preferencia=' + encodeURIComponent(JSON.stringify(preferencia)));

  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para horarios----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getHorarios(horario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + ((horario)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + ((horario)));
  }


  getHorario2Conductor(horario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + ((horario)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + ((horario)));
  }


  setHorario(horario: Horarios): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&horario=' + (JSON.stringify(horario)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + encodeURIComponent(JSON.stringify(horario)));
  }
  updateHorario(horario: HorarioPeticion): Observable<any> {
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosU.php?tkn=' + (JSON.stringify(this.tkn))
      + '&horario=' + (JSON.stringify(horario)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + encodeURIComponent(JSON.stringify(horario)));
  }
  borraHorarios(id: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const horario = new HorarioPeticion();
    horario.id = id;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosD.php?tkn=' + (JSON.stringify(this.tkn)
      + '&horario=' + (JSON.stringify(horario))));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&horario=' + encodeURIComponent(JSON.stringify(horario)));

  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para acontecimientos----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getAcontecimientos(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/acontecimientosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/acontecimientosS.php?tkn='
      + encodeURIComponent(JSON.stringify(this.tkn)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setEmp2ciu(emp2ciu: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&emp2ciu=') + encodeURIComponent(emp2ciu));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&emp2ciu=') + encodeURIComponent(emp2ciu));
  }
  getEmp2ciu(idCompania: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    let dat = JSON.stringify({ 'idEmpresa': idCompania });

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuS.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&emp2ciu=') + (dat));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&emp2ciu=') + encodeURIComponent(dat));
  }
  upEmp2ciu(emp2ciu: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&emp2ciu=') + encodeURIComponent(emp2ciu));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2ciuU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&emp2ciu=') + encodeURIComponent(emp2ciu));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

}
