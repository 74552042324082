import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { Global } from '../../comun/global';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { Md5 } from 'ts-md5/dist/md5';
import { Ciudad } from '../../comun/ciudad';
import { StarifasService } from '../../servicios/starifas.service';
import { SstService } from '../../servicios/sst.service';
import { ConfirmationService } from 'primeng/api';


interface Tabla {
  table_name: string;
}

@Component({
  selector: 'app-bus-uni',
  templateUrl: './bus-uni.component.html',
  styleUrls: ['./bus-uni.component.css'],
  providers: [SserService, SconductoresService, SempresasService, StarifasService, SstService, SstService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class BusUniComponent implements OnInit {


  @Output() cerrarResUniEvento = new EventEmitter();

  verLog: boolean = true;
  columnas: string[] = [];
  tablas: Tabla[] = [];
  hayTabla: boolean = false;
  signos: any[] = [];
  lineas: any[] = [];
  lineasR: any[] = [];
  //servicios: Servicio[] = [];
  resultados: any[] = [];
  campo: string;
  tabla: string;
  idSigno: string;
  signo: string;
  signoV: string;
  valores: string[] = [];
  valores2: string[] = [];
  a: string[] = [];
  b: string[] = [];
  esEntreFechas: boolean[] = [];
  esFecha: boolean[] = [];
  visibleRes: boolean = false;
  global: Global = new Global();


  constructor(private sser: SserService, private confirmationService: ConfirmationService, private sscond: SconductoresService) { }

  ngOnInit() {
    this.signos = [{ 'id': '1', 'nombre': " IGUAL QUE ", 'valor': "='" },
    { 'id': '2', 'nombre': " DIFERENTE QUE", 'valor': "!= '" },
    { 'id': '3', 'nombre': " CONTIENE ", 'valor': "LIKE '%-" },
    { 'id': '4', 'nombre': " NO CONTIENE ", 'valor': " NOT LIKE '%-" },
    { 'id': '5', 'nombre': " ENTRE ", 'valor': "BETWEEN '" },
    { 'id': '6', 'nombre': " MAYOR QUE ", 'valor': "> '" },
    { 'id': '7', 'nombre': " MENOR QUE ", 'valor': "< '" },
    { 'id': '8', 'nombre': " MAYOR O IGUAL QUE ", 'valor': ">= '" },
    { 'id': '9', 'nombre': " MENOR O IGUAL QUE ", 'valor': "<= '" }];

    if (this.verLog) console.log('signos-->', this.signos);
    this.getTablas();
  }
  getTablas() {
    let sql = "SELECT table_name FROM information_schema.tables WHERE table_schema = 'utaxi' ORDER BY table_name ASC";
    this.sser.getItems(sql).subscribe(result => {
      this.tablas = result;
      this.tablas = this.tablas.filter(index => {
        let res = '';        
        (index.table_name.includes('sesion') || index.table_name.includes('2') || index.table_name.includes('old') || index.table_name.includes('bck'))? '' : res=index.table_name;
        console.log('index.table_name-->', res);//
        return res;
      });
      if (this.verLog) console.log('tablas-->', this.tablas);
    });
  }
  getColumns(tabla: string) {
    let sql = "SELECT COLUMN_NAME FROM INFORMATION_SCHEMA.Columns WHERE TABLE_NAME = '" + tabla + "'";

    this.sser.getItems(sql).subscribe(result => {
      this.columnas = result;
      this.hayTabla = true;
      if (this.verLog) console.log('columnas-->', this.columnas);
    });
  }
  selCampo(campo: string) {
    if (this.verLog) console.log('campo-->', campo);
    this.campo = campo;
  }
  selTabla(tabla: string) {
    this.limpiar();
    if (this.verLog) console.log('tabla-->', tabla);
    this.tabla = tabla;
    this.getColumns(tabla);
  }
  limpiar() {
    this.resultados = [];
    this.visibleRes = false;
    this.hayTabla = false;
    this.lineas = [];
    this.lineasR = [];
    this.esEntreFechas = [];
    this.valores = [];
    this.valores2 = [];
    this.a = [];
    this.b = [];
    this.campo = '';
    this.idSigno = '';
    this.signo = '';
    this.signoV = '';
  }
  selSigno(idSigno: string) {
    let s = this.signos.filter(index => index.id == idSigno);
    if (s && s.length > 0) {
      this.idSigno = s[0].id;
      this.signo = s[0].nombre;
      this.signoV = s[0].valor;
      if (this.verLog) console.log('selSigno::signo-->', s);
    } else {
      if (this.verLog) console.log('selSigno::error-->No lo encuentra');
    }

  }
  anadir() {
    let esEntreFechas = false;
    if (this.campo && this.campo != '' && this.signo && this.signo != '' && this.signoV && this.signoV != '') {
      if ((this.campo.includes('Fecha') || this.campo.includes('fecha')) && this.signo.includes('ENTRE')) {
        this.esEntreFechas[this.lineas.length] = true;
        this.esFecha[this.lineas.length] = false;
      } else if ((this.campo.includes('Fecha') || this.campo.includes('fecha')) && !this.signo.includes('ENTRE')) {
        this.esEntreFechas[this.lineas.length] = false;
        this.esFecha[this.lineas.length] = true;
      } else {
        this.esEntreFechas[this.lineas.length] = false;
        this.esFecha[this.lineas.length] = false;
      }
      let linea = "`" + this.campo + "` " + this.signo;
      let lineaR = "`" + this.campo + "` " + this.signoV;
      if (this.verLog) console.log('añadir-->', linea);
      this.lineas.push(linea);
      this.lineasR.push(lineaR);
      this.campo = '';
      this.idSigno = '';
      this.signo = '';
      this.signoV = '';
      if (this.verLog) console.log('valores-->', this.valores);
      if (this.verLog) console.log('esEntreFechas-->', this.esEntreFechas);
    } else {
      if (this.verLog) console.log('anadir::error--> no hay valores');
    }
  }
  ver() {
    this.resultados = [];
    this.visibleRes = false;
    let sql = " SELECT * FROM `"+this.tabla+"` WHERE 1 ";
    if (this.verLog) console.log('this.lineasR-->', this.lineasR);
    if (this.verLog) console.log('this.valores-->', this.valores);
    if (this.lineasR && this.valores && this.lineasR.length > 0 && this.lineasR.length == this.valores.length) {
      for (let i in this.lineasR) {
        let s = this.lineasR[i];
        if (s && s != '' && s.includes('LIKE')) {
          sql += ' AND ' + s + '' + this.valores[i] + "\%\'";
        } else if (s && s != '' && s.includes('BETWEEN')) {
          sql += ' AND ' + s + '' + this.valores[i] + "' AND '" + this.valores2[i] + "'";
        } else if (s && s != '') {
          sql += ' AND ' + s + '' + this.valores[i] + "'";
        } else {
          if (this.verLog) console.log('ver::error--> linea vacias');
        }
      }
      this.getSql(sql);
    } else {
      if (this.verLog) console.log('ver::error--> no hay valores');
      this.Nok('Error, establezca valores para todos los campos.');

    }
  }

  dataChanged($event, i: string, b: boolean) {
    if (b) if (this.verLog) console.log('dataChanged--> i=' + i + '  -->' + this.valores[i]);
    else if (this.verLog) console.log('dataChanged--> i=' + i + '  -->' + this.valores2[i]);
    let e = moment($event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (b) {
      this.valores[i] = e;
    } else {
      this.valores2[i] = e;
    }
    if (b) {
      if (this.verLog) console.log('dataChanged--> i=' + i + '  -->' + this.valores[i]);
    } else {
      if (this.verLog) console.log('dataChanged--> i=' + i + '  -->' + this.valores2[i]);
    }
  }
  getSql(sql: string) {

    if (this.verLog) console.log('sql--> ', sql);
    this.sser.getItems(sql).subscribe(result => {
      if (result && result.length > 0) {
        this.resultados = result;
        if (this.verLog) console.log('resultados-->', this.resultados);
        if (this.resultados && this.resultados.length > 0) this.visibleRes = true;
      } else {
        this.Nok('No hay valores, ajuste el filtro.');
      }
    });
  }
  borrar(i: string) {
    this.visibleRes = false;
    this.resultados = [];
    this.valores.splice(+i, 1);
    this.valores2.splice(+i, 1);
    this.lineas.splice(+i, 1);
    this.lineasR.splice(+i, 1);
    this.esEntreFechas.splice(+i, 1);
    this.a.splice(+i, 1);
    this.b.splice(+i, 1);
    //this.ver();

  }

  Nok(msg: string) {
    this.confirmationService.confirm({
      key: "Nok",
      message: msg,
      accept: () => {
      }
    });
  }
  Ok(msg: string, sql: string) {
    this.confirmationService.confirm({
      key: "Ok",
      message: msg,
      accept: () => {
        this.getSql(sql);
      }
    });
  }
}
