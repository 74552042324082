import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../servicios/sser.service';


import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../comun/servicio';
import { TipoDeServicios } from '../comun/tipo-de-servicios';
import { Notificaciones } from '../comun/notificaciones';
import { SmailService } from '../servicios/smail.service';
import { Message } from 'primeng/api';
import { Global } from '../comun/global';
import { Alertas } from '../comun/alertas';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.component.html',
  styleUrls: ['./alertas.component.css'],
  providers: [SserService]
})
export class AlertasComponent implements OnInit {

  alertas: Alertas[];
  visible: boolean = false;
  intervalId: any;
  @Output() cerrarAleEvento = new EventEmitter();
  sortOptions: any[];
  sortField: string;
  sortOrder: number;
  sortKey: any;
  onSortChange: any;
  emailResp: string;
  cuerpo: string;
  asunto: string;
  destacar: string;

  ssCondMails: any[] = [];
  ssCliMails: any[] = [];
  selectedValuesCli: any[] = [];
  selectedValuesCond: any[] = [];

  constructor(private sser: SserService) { }

  ngOnInit() {

    this.getMailsCondCli();

    this.sortOptions = [
      { label: 'fecha mas reciente', value: '!fechaAlta' },
      { label: 'fecha mas antiguo', value: 'fechaAlta' },
      { label: 'Estado', value: 'estado' }
    ];
    this.sortOrder = -1;
    this.sortField = "fechaAlta";

  }
  getAlertas(email: string) {
    const config = JSON.stringify({ 'tabla': 'alertas', 'conEstado': '0' });
    const filtro = JSON.stringify({ 'filtro': 'para', 'valor': email, 'traza': '0' });
    const filtro1 = JSON.stringify({ 'filtro1': 'estado', 'valor1': 'Activo', "operator1": "AND" });
    const filtro2 = null;//JSON.stringify({ 'filtro2': 'para', 'valor2': 'Todos',"operator2":"OR"});

    this.sser.lista(config, null, filtro, filtro1, filtro2).subscribe(result => {
      this.alertas = result;
    });
  }
  inactivarAlerta(alerta: Alertas) {
    alerta.estado = "Inactiva";

    const config = JSON.stringify({ 'tabla': 'alertas', 'id': alerta.id });

    this.sser.actualizaConBorrado(config).subscribe(result => {
      if(localStorage.getItem('debug')=='1') if(localStorage.getItem('debug')==='1') console.log(JSON.stringify(result));
      this.ngOnInit();
    });
  }
  responder(alerta: Alertas) {
    this.visible = false;
    this.emailResp = alerta.de;
    this.cuerpo = '-------------' + "\n" + alerta.cuerpo + "\n" + '-------------' + "\n";
    this.asunto = 'Re: ' + alerta.titulo;
    this.destacar = alerta.importancia;
    window.scrollTo(0,0);

    this.visible = true;
  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  getMailsCondCli() {
    var datos = JSON.stringify({ "esCondCli": '0' });
    this.sser.getMailsCondCli(datos).subscribe(result1 => {
      this.ssCondMails = result1;
      var datos = JSON.stringify({ "esCondCli": '1' });
      this.sser.getMailsCondCli(datos).subscribe(result => {
        this.ssCliMails = result;
        var email = localStorage.getItem('username');
        this.getAlertas(email);
      });
    });
  }

  getAlertaNombreEmpresa(emailResp:string):string{
    let c = null;
    if (emailResp && this.ssCondMails && this.ssCondMails.length > 0) {
      for (let c of this.ssCondMails) {
        let aa = c.email.replace(/\s/g, "").toLowerCase();
        let bb = emailResp.replace(/\s/g, "").toLowerCase()
        if (aa == bb) {
         return(c.nombre);
        }
      }
    }
    if (emailResp && this.ssCliMails && this.ssCliMails.length > 0) {
      for (let c of this.ssCliMails) {
        let aa = c.email.replace(/\s/g, "").toLowerCase();
        let bb = emailResp.replace(/\s/g, "").toLowerCase()
        if (aa == bb) {
          return(c.nombre);
        }
      }
    }

  }
}

