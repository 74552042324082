import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConductorOcupado } from '../../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { SempresasService } from '../../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../../comun/servicio';
import { Global } from '../../../comun/global';
import { Empresas } from '../../../comun/empresas';
import { Cliente } from '../../../comun/cliente';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Md5 } from 'ts-md5/dist/md5';
import { Ciudad } from '../../../comun/ciudad';
import { StarifasService } from '../../../servicios/starifas.service';
import { SstService } from '../../../servicios/sst.service';
import { ConfirmationService } from 'primeng/api';
import { Propietario } from '../../../comun/propietario';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Documento } from '../../../comun/documento';

@Component({
  selector: 'app-buscar-pres',
  templateUrl: './buscar-pres.component.html',
  styleUrls: ['./buscar-pres.component.css'],
  providers: [SserService, SconductoresService, SempresasService, StarifasService, SstService, SstService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class BuscarPresComponent implements OnInit {
  @Output() cerrarNuevoPresEvento = new EventEmitter();

  verLog: boolean = true;

  empresas: Empresas[] = [];
  selEmpresa: Empresas;
  numeroPres:string;
 

  docs:Documento[] = [];


  hayEmpresa: boolean = false;
  haySelSer: boolean = false;

  servicios: Servicio[] = [];
  selServicios: Servicio[] = [];

  fechaFin:string;
  fecha:string;

  base=0;
  iva = 0;
  propietario: Propietario;
  hayPres: boolean = false;
  presNum: string = '';
  empresa: Empresas;
  hayPdf: boolean = false;

  title = 'LUXUCAR';
  dd: any;
  pdfDocGenerator: any;
  body: any = [];
  anio: string = moment().format('YY');
  url:string='';

  global: Global = new Global();
  columnas: string[] = [];
  nombre:any;




  constructor(private sser: SserService, private confirmationService: ConfirmationService, private sscond: SconductoresService) { 
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    //this.fechaFin = moment().format('DD/MM/YYYY');
    this.getColumns('documentos');
    this.getEmpresas();
 
  }

  getEmpresas() {
    let sql = "SELECT * FROM `empresas` WHERE `fechaBaja`='0000-00-00' AND `estado`='activo' ORDER BY `nombre`  ASC";
    this.sser.getItems(sql).subscribe(result => {
      this.empresas = result;
      if (this.verLog) console.log('empresas-->', this.empresas);
    });
  }
  inpChg($event) {
    this.hayPres=false;
    if (this.verLog) console.log('input variar -->', $event);
  }
  dataChanged($event) {
    this.hayPres=false;
    this.fecha = moment($event, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (this.verLog) console.log('this.fecha-->', this.fecha);
  }
  listEmp(event: any) {
    this.hayPres=false;
    if (this.verLog) console.log('selEmp-->', this.selEmpresa);
  }


  busPres(){
    if (this.verLog) console.log('+++fecha-->', this.fecha);
    if((this.numeroPres && this.numeroPres!='') || (this.selEmpresa && this.selEmpresa.id!='') || (this.fecha && this.fecha!='' && !this.fecha.includes('Invalid'))){
      let sql="SELECT * FROM `documentos` WHERE 1 ";
      if(this.fecha && this.fecha!='' && !this.fecha.includes('Invalid'))   sql+=" AND `fecha`>='"+this.fecha+" 00:00:00'  AND `fecha`<='"+this.fecha+" 23:59:59'";
      if(this.selEmpresa && this.selEmpresa.id!='') sql+=" AND `idRef` = '"+this.selEmpresa.id+"'";
      if(this.numeroPres && this.numeroPres!='')    sql+=" AND `descripcion` LIKE '%-"+this.numeroPres+"%'";

      if (this.verLog) console.log('sql-->', sql);
      this.sser.getItems(sql).subscribe(result => {
        if(result && result.length>0){
          this.docs = result;
          if (this.verLog) console.log('docs-->', this.docs);
          this.hayPres=true;
        }else{
          this.hayPres=false;
          let msg='No hay resultados, elija otro filtro y realice una nueva busqueda';
          this.Nok(msg);
        }
      });
    }else{
      let msg='Elija algun filtro, para realizar la busqueda';
      this.Nok(msg);
    }
  }
  getColumns(tabla: string) {
    let sql = "SELECT COLUMN_NAME FROM INFORMATION_SCHEMA.Columns WHERE TABLE_NAME = '" + tabla + "'";

    this.sser.getItems(sql).subscribe(result => {
      this.columnas = result;
      if (this.verLog) console.log('columnas-->', this.columnas);
    });
  }
  Nok(msg: string) {
    this.confirmationService.confirm({
      key: "Nok",
      message: msg,
      accept: () => {
      }
    });
  }
  Ok(msg: string, fin: boolean) {
    this.confirmationService.confirm({
      key: "Ok",
      message: msg,
      accept: () => {
        (fin) ? this.cerrarNuevoPresEvento.emit() : '';
      },
      reject: () => {

      }
    });
  }
}
