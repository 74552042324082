import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';

import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';
// import {ConfirmationService} from 'primeng/api';


@Component({
  selector: 'app-crud-cliente-list',
  templateUrl: './crud-cliente-list.component.html',
  styleUrls: ['./crud-cliente-list.component.css'],
  providers: [SempresasService, SconductoresService]
})
export class CrudClienteListComponent implements OnInit {
  @Input() clienteListVis: boolean;
  @Input() detalleClientevisible: boolean;
  // @Output() cerrarClienteListEvento = new EventEmitter();
  clienteVis: boolean;
  detalleVis: boolean;
  clientes: Cliente[];
  empresas: Empresas[];
  conductores: Conductor[];
  message: string[];
  selectedValues = [];
  idCiudad:string="";

  constructor(private sempresas: SempresasService, private scon: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.clienteVis = true;
    this.detalleVis = false;
    this.clienteListVis = false;
    this.detalleClientevisible = false;
    this.getEmpresas();
    this.getCliente();
    this.getConductores();
  }
  getConductores() {
    this.scon.getConductores(null).subscribe(result => {
      this.conductores = result;
    });
  }
  getNotify(notify:string){
    return (notify=='1')?'Si':'No';
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      this.empresas.unshift({nombre: 'todos', id: '1', cif: '', direccion: '', telefono: '', contactop: ''
                             , mailp: '', visible: false, estado: '', fechaAlta: '', fechaBaja: '',nombreComercial:'', telefonoTrafico:''});
    });
  }
  getCliente() {
    this.sempresas.getClientes().subscribe(result => {
      this.clientes = result;
    });
  }
  getCliente2empresa(empresaId: string) {
    this.sempresas.getCliente2empresa(empresaId).subscribe(result => {
      this.clientes = result;
    });
  }
  getEsAdmin(esAdmin:string){
    if(esAdmin && esAdmin=="1") return true;
    else return false;
  }
  borraCliente(id: string) {
    this.message[id] = '¡¡Atencion, ha inactivado el cliente!!';
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 2500);
    this.sempresas.borraCliente(id).subscribe();
    this.actualizarLista();

  }
  actualizaLista(id: any) {
    if ( id === '1') {
      this.getCliente();
    }else {
      this.getCliente2empresa(id);
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
}

