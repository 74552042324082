import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SempresasService } from '../../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../../comun/empresas';
import { Cliente } from '../../../comun/cliente';
import { Global } from '../../../comun/global';
import * as XLSX from 'xlsx';




@Component({
  selector: 'app-res-uni',
  templateUrl: './res-uni.component.html',
  styleUrls: ['./res-uni.component.css'],
  providers: [SserService],
  encapsulation: ViewEncapsulation.None
})
export class ResUniComponent implements OnInit {

  @Input() resultados: any;
  @Input() cols: any;
  @Input() tabla: string;

  selectedColumns: any[] = [];
  selectedProducts: Servicio[] = [];

  global: Global = new Global();
  constructor() { }

  ngOnInit() {
    this.selectedColumns = JSON.parse(JSON.stringify(this.cols));
  }
  exportPdf() {
    /*   import('jspdf').then((jsPDF) => {
          import('jspdf-autotable').then((x) => {
              const doc = new jsPDF.default('p', 'px', 'a4');
              (doc as any).autoTable(this.exportColumns, this.products);
              doc.save('products.pdf');
          });
      }); */
  }

  exportExcel() {
    // xlsx => {
    console.log('selectedColumns------->', this.selectedColumns);
    console.log('resultados------->', JSON.stringify(this.resultados));

    let ss = "[";
    for (let s of this.resultados) {
      let l = '{'
      for (let c of this.selectedColumns) {
        console.log('s------->', s);
        console.log('c.COLUMN_NAME------->', c.COLUMN_NAME);
        console.log('s[c.COLUMN_NAME]------->', s[c.COLUMN_NAME]);
        if (s[c.COLUMN_NAME]) {
          l += '"' + c.COLUMN_NAME + '":"' + s[c.COLUMN_NAME] + '",';
        }
      }
      l = l.substring(0, l.length - 1);
      l += '},';
      console.log('l------->', l);
      ss += l;
    }
    ss = ss.substring(0, ss.length - 1);
    ss+="]";
    console.log('ss------->', ss);

    //let s = (this.selectedProducts && this.selectedProducts.length > 0) ? this.selectedProducts : this.servicios;
    const worksheet = XLSX.utils.json_to_sheet(JSON.parse(ss));
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, this.tabla,workbook);
    //  });
  }

  saveAsExcelFile(buffer: any, fileName: string, workbook:any): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    //FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    XLSX.writeFile(workbook, fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
