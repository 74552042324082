import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { StarifasService } from '../../../servicios/starifas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Observable } from 'rxjs';
import * as moment from 'moment';


@Component({
  selector: 'app-crud-estados',
  templateUrl: './crud-estados.component.html',
  styleUrls: ['./crud-estados.component.css'],
  providers: [StarifasService]
})
export class CrudEstadosComponent implements OnInit {

  @Input() estadoVis: boolean;
  @Output() cerrarEstadoEvento = new EventEmitter();

  estadoNuevo: Estados = new Estados();

  constructor(private starifas: StarifasService) { }

  ngOnInit() {
    this.estadoVis = false;
  }
  new() {
    this.estadoNuevo.core=0;
     this.starifas.setEstado(this.estadoNuevo).subscribe();
    this.cerrarEstadoEvento.emit();
  }
}
