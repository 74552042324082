import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
// import {HttpModule} from '@angular/http';

@Component({
  selector: 'app-list-periodo',
  templateUrl: './list-periodo.component.html',
  styleUrls: ['./list-periodo.component.css'],
  providers: [SserService]
})
export class ListPeriodoComponent implements OnInit {
  @Input() serListPerVis: boolean;
  @Output() cerrarSerListPerEvento = new EventEmitter();
  servicios: Servicio[];
  fecha: string;
  fechaIni: string;
  fechaFin: string;
  message: string[];
  ventanaTarifas: boolean[];
  visible: boolean;
  semana: string;
  urgentes: boolean;
  idCiudad:string = "";
 
  constructor(private sser: SserService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.urgentes = false;
    this.semana = '0';
    this.visible= false;
    this.serListPerVis = false;
    this.fechaIni = moment().format('DD/MM/YYYY');
    if(localStorage.getItem('debug')==='1') console.debug('this.fechaIni=='+this.fechaIni);
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.actualizar();
    this.ventanaTarifas = new Array ();

  }
  actualizar() {
    
    this.visible = true;
    const fech_Ini = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fech_Fin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.sser.getSerFech(fech_Ini, null, null, null, null,fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
    });
  }
  actualizarLista() {
    this.ngOnInit();
  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizar();
  }











}
