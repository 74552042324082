import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Ciudad } from '../../../../comun/ciudad';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../../servicios/sser.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Global } from '../../../../comun/global';
import { Cliente } from '../../../../comun/cliente';
import { DataViewModule } from 'primeng/dataview';
import {ConfirmationService} from 'primeng/api';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Vehiculo } from '../../../../comun/vehiculo';


@Component({
  selector: 'app-list-servicios-fech',
  templateUrl: './list-servicios-fech.component.html',
  styleUrls: ['./list-servicios-fech.component.css'],
  providers: [SserService,ConfirmationService, SconductoresService],
  encapsulation: ViewEncapsulation.None

})
export class ListServiciosFechComponent implements OnInit {
  @Input() servicios: Servicio[]=[];
  @Input() vehiculos: Vehiculo[]=[];
  @Input() ciudades: Ciudad[]=[];
  @Output() cerrarListSerFechEvento = new EventEmitter();
  displayDialog: boolean;
  selectedSer: Servicio;
  sortOptions: any[];
  sortField: string;
  global: Global = new Global();
  sortOrder: number;
  showEdit: boolean;
  peq1:boolean = false;
  peq2:boolean = false;
  sortKey:any;
  conductores:Conductor[]=[];
  
  constructor(private sser:SserService,private confirmationService: ConfirmationService, private sscond:SconductoresService) {
  }

  ngOnInit() {
    
    this.showEdit = false;
    this.sortOptions = [
      { label: 'fecha mas reciente', value: '!FechaDeRecogida' },
      { label: 'fecha mas antiguo', value: 'FechaDeRecogida' },
      { label: 'Ciudad', value: 'idCiudad' },
      { label: 'Estado', value: 'estado' }
    ];
    this.sortOrder = -1;
    this.sortField = "FechaDeRecogida";
    this.getConductores();
  }
  getTelf_Cond(id:string){
    for(let c of this.conductores){
      if(c.id==id) return c.telefono;
    }
  }
  getConductores(){
    this.sscond.getConductores(null).subscribe(result=>{
      this.conductores=result;
    });
  }
  getCiudad(idCiudad): string {
    let res = '';
    let c = this.ciudades.filter(item => item.id == idCiudad);
    if (c && c.length > 0) {
      res = c[0].nombre;
    }
    return res;
  }
  selectSer(event: Event, ser: Servicio) {
    this.selectedSer = ser;
    this.displayDialog = true;
    event.preventDefault();
  }
  onDialogHide() {
    this.selectedSer = null;
  }
  onSortChange(event) {
    let value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  fech2time(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('DD/MM HH:mm:ss');
  }
  actualizar(){
    this.cerrarListSerFechEvento.emit();
  }
  borraAlert(servicio:Servicio){
      this.confirmationService.confirm({
        message: 'Va a Borrar el servicio ¿Esta seguro?',
        accept: () => {
            let ser = {"idservicio":servicio.idservicio};
           this.sser.borraServicio(JSON.stringify(ser)).subscribe(result=>{
              //if(localStorage.getItem('debug')==='1') console.log('Borrar servicio :::::>>>>>>'+JSON.stringify(result));
              this.actualizar();
           });
        }
    });
    
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  urgente(servicio:Servicio){
    this.confirmationService.confirm({
      message: 'Va a establecer el servicio como urgente ¿Esta seguro?',
      accept: () => {
        servicio.estado = this.global.ESTADO_EN_PETICION;
        var nuevoSer = JSON.parse(JSON.stringify(servicio, this.replacer));
        this.sser.setServicio(nuevoSer).subscribe(result=>{
          //if(localStorage.getItem('debug')==='1') console.log('Borrar servicio :::::>>>>>>'+JSON.stringify(result));
          this.actualizar();
       });
      }
    });
  }
  buscarVuelo(vuelo:string, id:string){
    if(localStorage.getItem('debug')==='1') console.log(':::::>>>>'+id);
    if(id=='925'){
       if(localStorage.getItem('debug')==='1') console.log(':-----::::>>>>'+id);
        this.sser.buscarGoogle(vuelo).subscribe(result=>{
          if(localStorage.getItem('debug')==='1') console.log(':::::>>>>>'+JSON.stringify(result));
        });
    }

  }
  getConductorMatricula(idCond: string) {
    for (let c of this.conductores) {
      if (c.id == idCond) {
        let v = this.vehiculos.filter(item => item.id == c.idVehiculo)
        return   c.nombre +' ('+v[0].matricula+ ')/ ' + c.telefono ;
      }
    }
  }
}
