import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Cliente } from '../../../../comun/cliente';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.css'],
  providers: [SempresasService, SconductoresService]
})
export class DetalleClienteComponent implements OnInit {

  @Input() cliente: Cliente;
  @Input() conductores: Conductor[];
  detalleClientevisible: boolean;
  clienteNueva: Cliente;
  @Output() guardarClienteEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarClienteListEvento = new EventEmitter();
  dealleVis: boolean;
  empresas: Empresas[];
  estados: Estados[];
  selectedValues = [];
  checked: boolean = true;
  esAdmin:boolean = false;
  esExp:boolean = false;
  idCiudad:string="";

  constructor(private sempresas: SempresasService, private scon: SconductoresService) { }

  ngOnInit() {

    if (this.cliente && this.cliente.esAdmin =="1") this.esAdmin=true;
    if (this.cliente && (this.cliente.esExp =="si" || this.cliente.esExp =="Si" || this.cliente.esExp =="SI")) this.esExp=true;
    
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.clienteNueva = JSON.parse(JSON.stringify(this.cliente, this.replacer));
    this.clienteNueva.visible = false;
    this.getEmpresas();
    this.getEstadosbd();
    this.getCondutoresPreferidos();
    if (this.clienteNueva.recibirNot == 1) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }
  replacer(key, value) {

    if (key === 'nombreEmpresa') {
      return undefined;
    } else if (key === 'conductoresLista') {
      return undefined;
    }
    if (value === null) {
      return undefined;
    } else {
      return value;
    }
  }
  actualizaCliente() {

    if (this.checked) this.clienteNueva.recibirNot = 1;
    else this.clienteNueva.recibirNot = 0;
    console.log(this.clienteNueva);
    this.sempresas.upCliente(this.clienteNueva).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarClienteListEvento.emit();
  }
  getCondutoresPreferidos() {
    this.selectedValues = [];
    const cli = ({ 'idCliente': this.cliente.id.toString() });
    this.sempresas.getconductores2Cliente(JSON.stringify(cli)).subscribe(result => {
      const res = result;
      for (const r of res) {
        r.conductores = '' + r.conductores;

        if (r.conductores && r.conductores.length > 0 && r.conductores.indexOf('-') > 0) {
          const spl = r.conductores.split('-');
          for (const s of spl) {
            for (let c of this.conductores) {
              if (c.id == s) this.selectedValues.push(c);
            }
          }
        } else if (r.conductores && r.conductores.length > 0 && r.conductores.indexOf('-') == -1) {
          for (let c of this.conductores) {
            if (c.id == r.conductores) this.selectedValues.push(c);
          }

        }
      }
    });

  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  getConductores() {
    this.scon.getConductores(null).subscribe(result => {
      this.conductores = result;
    });
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.clienteNueva.idempresa = idEmpresa;
  }

  asignoEstado(estado: string) {
    this.clienteNueva.estado = estado;
    if (estado === 'inactivo') {
      this.clienteNueva.fechaBaja = moment().format('YYYY-MM-DD');
    } else {
      this.clienteNueva.fechaBaja = '0000-00-00';
      this.clienteNueva.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  handleChange($event){

    this.clienteNueva.esAdmin = (this.esAdmin?'1':'0');

  }
  handleChange1($event){

    this.clienteNueva.esExp = (this.esExp?'Si':'No');

  }
}
