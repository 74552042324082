import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { ConductorOcupado } from '../../../comun/conductor-ocupado';

import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
// import {HttpModule} from '@angular/http';
import { Notificaciones } from '../../../comun/notificaciones';
import { SmailService } from '../../../servicios/smail.service';

@Component({
  selector: 'app-asigna-conductor',
  templateUrl: './asigna-conductor.component.html',
  styleUrls: ['./asigna-conductor.component.css'],
  providers: [SserService, SconductoresService,SmailService]
})
export class AsignaConductorComponent implements OnInit {
  @Input() servicio: Servicio;
  @Output() cerrarConductorEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();

  conductores: Conductor[]=[];
  conductoresOcupados: ConductorOcupado[];
  conductoresVacaciones: ConductorOcupado[];
  conduct: Conductor;
  idconductor: any;
  tarifaConductor: string;
  idTipoDeServicio: string;
  notificaciones:Notificaciones;
  condOcupadoColor="#00FF00";
  condExentoColor="#FF0000";
  condLibreColor="#000000";

  constructor(private sser: SserService, private scon: SconductoresService, private smail: SmailService) {
    this.notificaciones = new Notificaciones(smail);
   }

  ngOnInit() {
    this.tarifaConductor = this.servicio.tarifaConductor;
    this.idTipoDeServicio =  this.servicio.idTipoDeServicio;
    this.getConductoresOcupados();
    
    
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  getConductores() {
    this.scon.getConductoresE(this.tarifaConductor, this.idTipoDeServicio).subscribe(result => {
      this.conductores = [];

      for (let res of result) {
        res.color=this.condLibreColor;

        let hayId = this.conductoresOcupados.filter(item => item.idConductor == res.id);
        //if(localStorage.getItem('debug')==='1') console.log('hayId---->>>>>'+JSON.stringify(hayId)); 
        if(hayId && hayId.length>0){
          res.color= this.condOcupadoColor;
        }

        hayId = this.conductoresVacaciones.filter(item => item.idConductor == res.id);
        if(hayId && hayId.length>0){
          res.color= this.condExentoColor;
        }
        this.conductores.push(res);
      }

    });
  }
  getConductoresOcupados() {

    const fechaRecogida = this.servicio.FechaDeRecogida;
    const idTipoDeServicio = this.servicio.idTipoDeServicio;
    let stms = this.servicio.tiempoMedioServicio;
    let stmr = this.servicio.tiempoMedioRecogida;

    const tms = parseInt (stms);
    const tmr = parseInt (stmr);
    stms = moment('00:00', 'HH:mm').add(tms, 'minutes').format('HH:mm');
    stmr = moment('00:00', 'HH:mm').add(tmr, 'minutes').format('HH:mm');


    const horaIni = moment(fechaRecogida, 'YYYY-MM-DDTHH:mm:ss').subtract(tmr, 'minutes').format('HH:mm:ss');
    const horaFin = moment(fechaRecogida, 'YYYY-MM-DDTHH:mm:ss').add(tms, 'minutes').format('HH:mm:ss');
    const fecha   = moment(fechaRecogida, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');

    const datos = JSON.stringify(({ tms: stms, tmr: stmr, horaIni: horaIni,
                                    horaFin: horaFin, fecha: fecha, idTipoDeServicio: idTipoDeServicio }));

    this.scon.getConductoresOcupados(datos).subscribe(result => {
      this.conductoresOcupados = result;
      this.getConductoresExentos();
    });
  }
  getConductoresExentos() {
    const fecha = this.servicio.FechaDeRecogida;
    const idTipoDeServicio = this.servicio.idTipoDeServicio;
    let stms = this.servicio.tiempoMedioServicio;
    let stmr = this.servicio.tiempoMedioRecogida;

    //if(localStorage.getItem('debug')==='1') console.log('stms---->>>>>' + stms);
    //if(localStorage.getItem('debug')==='1') console.log('stmr---->>>>>' + stmr);

    const tms = parseInt (stms);
    const tmr = parseInt (stmr);
    stms = moment('00:00', 'HH:mm').add(tms, 'minutes').format('HH:mm');
    stmr = moment('00:00', 'HH:mm').add(tmr, 'minutes').format('HH:mm');



    const fechaRecogida = moment(fecha, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

    const datos = JSON.stringify(({tms: stms, tmr: stmr, fechaRecogida: fechaRecogida }));
    // if(localStorage.getItem('debug')==='1') console.log('datos---->>>>>' + JSON.stringify(datos));
    this.scon.getConductoresExentos(datos).subscribe(result => {
      this.conductoresVacaciones = result;
      //if(localStorage.getItem('debug')==='1') console.log('this.conductoresVacaciones---->>>>>' + JSON.stringify(this.conductoresVacaciones));
      this.getConductores();
    });
   }
  asignoConductor(idConductor: string) {
    for (const con of this.conductores){
      if (con.id == idConductor) {
        this.conduct =  con;
        this.servicio.estado = 'Asignado';
      }
    }
  }
  guardarConductor() {
    if (this.servicio.estado == 'Asignado') {
      this.servicio.idConductor = this.conduct.id;
      this.servicio.Conductor = this.conduct.nombre;
      const email = this.conduct.email;
      //notifico al admin
      this.notificaciones.sendAsignadoServicioMail(email,this.conduct.id, this.servicio);
      this.servicio = JSON.parse(JSON.stringify( this.servicio, this.replacer));
      this.sser.setServicio(this.servicio).subscribe();
      this.cerrarConductorEvento.emit();
      this.servicio.visible1 = false;
    }

  }
  returnStyleColor(color:string):string{
    //if(localStorage.getItem('debug')==='1') console.log('returnStyleColor---->>>>>'+color);
    let result="";
    if(color==this.condOcupadoColor) result= "<<<  OCUPADO  >>>";
    if(color==this.condExentoColor) result = "<<<  EXENTO   >>>";
    return result;
  }
}
