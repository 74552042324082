import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Global } from '../comun/global';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Cargas } from '../comun/cargas';

@Injectable()
export class SserService {
  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();
  carpeta: string = '/std/';

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }

  setItems(datos: string, values: string): Observable<any> {
    if (this.global.LOG_SER) console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/' + 'itemIJS.php?datos=' + encodeURIComponent(datos) + '&values=' + encodeURIComponent(values)));
    return this.httpClient.get((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/' + 'itemIJS.php?datos=' + encodeURIComponent(datos)
      + '&values=' + encodeURIComponent(values)));
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios por fecha----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getSerFech(fecha: string, idCliente: string, idEmpresa: string, idConductor: string, estado: string, fechaFin: string, idCiudad: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    let fechaIni: any;

    if (idCliente) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idCliente: idCliente, idCiudad: idCiudad });
      } else {
        fechaIni = ({ fecha: fecha, idCliente: idCliente, idCiudad: idCiudad });
      }
    } else if (idEmpresa) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idEmpresa: idEmpresa, idCiudad: idCiudad });
      } else {
        fechaIni = ({ fecha: fecha, idEmpresa: idEmpresa, idCiudad: idCiudad });
      }
    } else if (idConductor) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idConductor: idConductor, idCiudad: idCiudad });
      } else {
        fechaIni = ({ fecha: fecha, idConductor: idConductor, idCiudad: idCiudad });
      }
    } else if (estado) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, estado: estado, idCiudad: idCiudad });
      } else {
        fechaIni = ({ fecha: fecha, estado: estado, idCiudad: idCiudad });
      }
    } else if (fechaFin) {
      fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idCiudad: idCiudad });
    } else {
      fechaIni = ({ fecha: fecha, idCiudad: idCiudad });
    }

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerFech.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + (JSON.stringify(fechaIni)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerFech.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(JSON.stringify(fechaIni)));

  }
  getSerFechNT(fecha: string, idCliente: string, idEmpresa: string, idConductor: string, estado: string, fechaFin: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    let fechaIni: any;

    if (idCliente) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idCliente: idCliente });
      } else {
        fechaIni = ({ fecha: fecha, idCliente: idCliente });
      }
    } else if (idEmpresa) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idEmpresa: idEmpresa });
      } else {
        fechaIni = ({ fecha: fecha, idEmpresa: idEmpresa });
      }
    } else if (idConductor) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, idConductor: idConductor });
      } else {
        fechaIni = ({ fecha: fecha, idConductor: idConductor });
      }
    } else if (estado) {
      if (fechaFin) {
        fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin, estado: estado });
      } else {
        fechaIni = ({ fecha: fecha, estado: estado });
      }
    } else if (fechaFin) {
      fechaIni = ({ fechaIni: fecha, fechaFin: fechaFin });
    } else {
      fechaIni = ({ fecha: fecha });
    }

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerFechNT.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + (JSON.stringify(fechaIni)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerFechNT.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(JSON.stringify(fechaIni)));

  }
  getSerFactCli(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactCliS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactCliS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(datos));
  }
  getSerFactComp(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactCondS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactCondS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(datos));
  }
  getItems(sql: string): Observable<any> {

    if (localStorage.getItem('debug') === '1') console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemJSP.php?sql=' +  sql));
    
    const endpoint = this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemJSP.php';
    const formData = new FormData();  
    formData.append('sql', sql);

    return this.httpClient.post(endpoint, formData);//,{headers:httpHeaders}
  }
  setItems_A(tabla: string, valores:string): Observable<any> {

    if (localStorage.getItem('debug') === '1') console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/item_I_A.php'));
    
    const endpoint = this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/item_I_A.php';
    const formData = new FormData();  
    formData.append('tabla', tabla);
    formData.append('valores', valores);

    console.log('valores------------------->>>>', valores);

    return this.httpClient.post(endpoint, formData);//,{headers:httpHeaders}
  }
  getSerGL(carga:Cargas): Observable<any>{
    if (localStorage.getItem('debug') === '1') console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/pdf-parse/ag.php'));
    
    const endpoint = this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/pdf-parse/ag.php';
    const formData = new FormData();  
    formData.append('carpeta', carga.ruta);
    formData.append('url', carga.hostname);
    formData.append('lap', carga.lap);
    formData.append('usuario', carga.username);
    formData.append('contrasenia', carga.password);
    formData.append('titulo', carga.subject);
    

    return this.httpClient.post(endpoint, formData);//,{headers:httpHeaders}
  }
  /*
  getItems(sql: string): Observable<any> {
    //sql = this.encTxt(sql);
    if (localStorage.getItem('debug') === '1') console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemJS.php?datos=' +  encodeURIComponent(sql)));
    return this.httpClient.get((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemJS.php?datos=' +  encodeURIComponent(sql)));
  }

  */
  setServicio(servicio: Servicio): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serU.php?tkn=' + (JSON.stringify(this.tkn))
      + '&servicio=' + (JSON.stringify(servicio)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&servicio=' + encodeURIComponent(JSON.stringify(servicio)));
  }
  newServicio(servicio: Servicio): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&servicio=' + (JSON.stringify(servicio)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&servicio=' + encodeURIComponent(JSON.stringify(servicio)));
  }
  getPropietario(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/propietarioS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/propietarioS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }

  setPropietario(propietario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/propietarioU.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/propietarioU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&propietario=' + encodeURIComponent(propietario));

  }

  getServicios(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/serviciosS_pla.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/serviciosS_pla.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }

  borraServicio(servicio: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&servicio=' + servicio);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&servicio=' + encodeURIComponent(servicio));

  }

  getServiciosUrg(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerUrg.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/listSerUrg.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }
  getExenciones(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS_pla.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS_pla.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }
  getMail2Fich(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/inbox.php?datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/inbox.php?datos=' + encodeURIComponent((datos)));
  }

  /*   newServicioI(servicio: string): Observable<any> {
  this.tkn.tkn=localStorage.getItem('tkn');
      if(localStorage.getItem('debug')==='1') console.log(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/ser/serII.php?servicio=' + servicio);
  
  
      return this.httpClient.get(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/ser/serII.php?servicio=' + encodeURIComponent(servicio));
  
    } */



  newServicioI(servicio: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serII.php?servicio=' + servicio);

    const formData = new FormData();
    formData.append('servicio', servicio);
    var endpoint = this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serII.php';

    return this.httpClient.post(endpoint, formData);

    //return this.httpClient.get(this.global.SERVIDOR_URL+this.global.ADM_SCRIPTS + '/ser/serIII.php?servicio=' + encodeURIComponent(servicio));

  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  borrarFile_nd(fd: FormData){
    var endpoint = this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/df_dir.php';
    
    return this.httpClient.post(endpoint,  fd);

  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCargas(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/cargasS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/cargasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }
  updateCarga(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/cargasU.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantServicios/cargasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getServicio(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  buscarGoogle(vuelo: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    let key = "AIzaSyApFaAKPnRlQRjlrs6IX0paMJrjzPvHRrE";
    let cx = "013250527992228250190:dswzh3cxdhq";
    if (localStorage.getItem('debug') === '1') console.log("https://www.googleapis.com/customsearch/v1?key=" + key + "&cx=" + cx + "&q=vuelo%20" + vuelo);

    return this.httpClient.get("https://www.googleapis.com/customsearch/v1?key=" + key + "&cx=" + cx + "&q=vuelo%20" + vuelo);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  upload(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/ssf.php?tkn=' + (JSON.stringify(this.tkn)));

    return this.httpClient.post(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/ssf.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      , 'datos=' + (datos), { headers: headers });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getFiles(dir: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');


    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/files.php?tkn=' + (JSON.stringify(this.tkn)) + '&dir=' + (dir));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/files.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&dir=' + (dir));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  lista(config: string, datos: string, filtro: string, filtro1: string, filtro2: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');


    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'listar.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + config : '')
      + (datos ? '&datos=' + datos : '')
      + (filtro ? '&filtro=' + filtro : '')
      + (filtro1 ? '&filtro1=' + filtro1 : '')
      + (filtro2 ? '&filtro2=' + filtro2 : ''));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'listar.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : '')
      + (datos ? '&datos=' + encodeURIComponent(datos) : '')
      + (filtro ? '&filtro=' + encodeURIComponent(filtro) : '')
      + (filtro1 ? '&filtro1=' + encodeURIComponent(filtro1) : '')
      + (filtro2 ? '&filtro2=' + encodeURIComponent(filtro2) : ''));
  }
  nuevo(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'nuevoIter.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'nuevoIter.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }


  actualiza(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'update.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + (config) : '')
      + (datos ? '&datos=' + (datos) : ''));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'update.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : '')
      + (datos ? '&datos=' + encodeURIComponent(datos) : ''));

  }
  actualizaConBorrado(config: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'updateConBorrado.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + (config) : ''));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'updateConBorrado.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : ''));

  }

  borra(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'borrar.php?tkn=' + (JSON.stringify(this.tkn))
      + '&config=' + config + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'borrar.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&config=' + encodeURIComponent(config) + '&datos=' + encodeURIComponent(datos));

  }
  getMailsCondCli(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/condCliMailSesion.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/condCliMailSesion.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }
  getMailsCondNSCli(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/condCliMailNSesion.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/condCliMailSesion.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getPosGlob(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/getPosGlob.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/getPosGlob.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getSerFactCom(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactComS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactComS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(datos));
  }


  envioFile(fileToUpload: File, dirBase: string, nametemp: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    this.tkn.tkn = localStorage.getItem('tkn');
    const formData = new FormData();
    formData.append('fichero', fileToUpload, fileToUpload.name);

    var re = /\s/g;
    var name = fileToUpload.name.replace(re, '_');

    var subdir = this.global.DIRBASE_IMG;

    const endpoint = this.global.SERVIDOR_URL_IMG;

    if (localStorage.getItem('debug') === '1') console.log(endpoint);

    formData.append('tkn', this.tkn.tkn);
    formData.append('name', nametemp + name);
    formData.append('dirB', dirBase);
    formData.append('dirS', subdir);

    return this.httpClient.post(endpoint, formData);//,{headers:httpHeaders}

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  envioFoto(fileToUpload: File, dirB: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const formData = new FormData();
    formData.append('fichero', fileToUpload, fileToUpload.name);

    var re = /\s/g;

    let ext = fileToUpload.name;
    let e = ext.split('.');
    ext = e[(e.length - 1)];

    var name = this.global.COND_LOG_NAME + '.' + ext;

    var subdir = this.global.DIRBASE_IMG_COND;

    const endpoint = this.global.SERVIDOR_URL_IMG;

    if (localStorage.getItem('debug') === '1') console.log(endpoint);

    formData.append('tkn', this.tkn.tkn);
    formData.append('name', name);
    formData.append('dirB', dirB);
    formData.append('dirS', subdir);

    return this.httpClient.post(endpoint, formData);//,{headers:httpHeaders}

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getImgSer(url: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(url);
    return this.httpClient.get(url);
  }

  /// NOTIFY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setPushNotify(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/push.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/push.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }

  addPushSubscriber(id: any, subId: any) {
    this.tkn = new Tkn();
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/subAdmI.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&subId=') + (subId) + ('&Id=') + encodeURIComponent(id));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/subAdmI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&subId=') + encodeURIComponent(subId) + ('&Id=') + encodeURIComponent(id));
  }
  upItems(datos: string, values: string): Observable<any> {
    console.log((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemUJS.php?datos=' + datos + '&values=' + values));
    return this.httpClient.get((this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/itemUJS.php?datos=' + encodeURIComponent(datos)
      + '&values=' + encodeURIComponent(values)));
  }

}
