import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Tarifa } from '../../../comun/tarifa';
import { Ruta } from '../../../comun/ruta';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { StarifasService } from '../../../servicios/starifas.service';
// import {HttpModule} from '@angular/http';

@Component({
  selector: 'app-asigna-tarifa',
  templateUrl: './asigna-tarifa.component.html',
  styleUrls: ['./asigna-tarifa.component.css'],
  providers: [SserService, StarifasService]
})
export class AsignaTarifaComponent implements OnInit {
  @Input() servicio: Servicio;
  @Output() cerrarTarifaEventoEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();
  idtarifa: any;
  tarifas: Tarifa[];
  tarifa: Tarifa;
  ruta: Ruta;
  idCiudad:string="";
  checked: boolean = false;

  constructor(private sser: SserService, private star: StarifasService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
      this.getTarifas(this.servicio.idEmpresa);
  }

  getTarifas(idEmpresa: string) {
    this.star.getTarifasEmpresas(idEmpresa, 'activo', this.idCiudad).subscribe(result => {
      this.tarifas = result;
    });
  }
  asignoTarifa(idTarifa: string) {
    //if(localStorage.getItem('debug')==='1') console.log('tarifa--->' + idTarifa);
    if (this.getTarifa(idTarifa)) {
      this.idtarifa = this.tarifa.id;
      this.servicio.idtarifa = this.tarifa.id;
      this.servicio.nombreTarifa = this.tarifa.nombre;
      this.servicio.tarifa = this.tarifa.tarifa;
      this.servicio.tarifaConductor = this.tarifa.tarifaConductor;
      this.servicio.tarifaConductorRed = this.tarifa.tarifaConductorRed;
      this.servicio.tarifaAnulacion = this.tarifa.tarifaAnulacion;
      this.servicio.tarifaAnuCon = this.tarifa.tarifaAnuCon;
      this.servicio.tarifaParada = this.tarifa.tarifaParada;
      this.servicio.tarifaParadaCon = this.tarifa.tarifaParadaCon;
      this.servicio.tarificacionPorTiempo = this.tarifa.tarificacionPorTiempo;
      if(this.tarifa.tarificacionPorTiempo==1)  this.servicio.tiempoTH= this.tarifa.tiempoTH;
      
      //if(localStorage.getItem('debug')==='1') console.log('tarifa--->' + this.tarifa.nombre);
      const tms = this.tarifa.idruta;
      this.star.getRuta(tms).subscribe(result => {
        this.ruta =  result;
        for (const rut of result){
            this.ruta =  rut;
          }
        //if(localStorage.getItem('debug')==='1') console.log('tiempoMedioServicio---->' + this.ruta.tiempoMedioServicio);
        this.servicio.tiempoMedioServicio = this.ruta.tiempoMedioServicio;
        this.servicio.tiempoMedioRecogida = this.ruta.tiempoMedioRecogida;
        this.servicio.estado = 'Administrando';
      });
    }
  }
  getTarifa(idTarifa: string) {
    let res = false;
    for (const tar of this.tarifas){
      if (tar.id == idTarifa) {
        res = true;
        this.tarifa = tar;
      }
    }
    return res;
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  actualizaServicio() {
    if (this.servicio.estado == 'Administrando') {
      this.servicio = JSON.parse(JSON.stringify( this.servicio, this.replacer));
      this.sser.setServicio(this.servicio).subscribe();
    }
    this.cerrarTarifaEventoEvento.emit();
    this.servicio.visible = false;
  }
  onSortChange() {
    if (this.tarifa.tarifaConductorRed) {
      if (this.servicio.tarifaConductor == this.tarifa.tarifaConductor) {
        this.servicio.tarifaConductor    = this.tarifa.tarifaConductorRed;
        this.servicio.tarifaConductorRed = this.tarifa.tarifaConductor;
      }else {
        this.servicio.tarifaConductor    = this.tarifa.tarifaConductor;
        this.servicio.tarifaConductorRed = this.tarifa.tarifaConductorRed;
      }
    }
  }
}
