export class Documento {
    id: string;
    idTipoDoc: string;
    url: string;
    contenido:Blob;
    descripcion:string;
    fecha:string;
    estadoBBDD:string;
    titulo:string;
    idRef:string;
}
