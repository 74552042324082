import { BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
/* import { HttpModule } from '@angular/http'; */

import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { CalendarModule, Schedule } from 'primeng/primeng';
import {ToggleButtonModule} from 'primeng/primeng';
import {SidebarModule} from 'primeng/primeng';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';
// import {ConfirmationService} from 'primeng/api';
import { UsuariosService } from './servicios/usuarios.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  } from 'fullcalendar';
import { ScheduleModule } from 'primeng/primeng';
import {ColorPickerModule} from 'primeng/primeng';
import { PlanificadorComponent } from './planificador/planificador.component';
import { AgmCoreModule } from '@agm/core';
import { Scheduler } from 'rxjs';
import { CrudEmpresaComponent } from './mant/mant-empresa/crud-empresa/crud-empresa.component';
import { CrudEmpresaListComponent } from './mant/mant-empresa/crud-empresa-list/crud-empresa-list.component';
import { MantEmpresaComponent } from './mant/mant-empresa/mant-empresa.component';
import { DetalleEmpresaComponent } from './mant/mant-empresa/crud-empresa-list/detalle-empresa/detalle-empresa.component';
import { LoginComponent } from './login/login.component';
import { AUTH_PROVIDERS} from './servicios/auth.service';
import { LogInGuard } from './log-in.guard';
import { HomeComponent } from './home/home.component';
import { CifradoComponent } from './cifrado/cifrado.component';
import { MantConductorComponent } from './mant/mant-conductor/mant-conductor.component';
import { MantTarifasComponent } from './mant/mant-tarifas/mant-tarifas.component';
import { MantComponent } from './mant/mant.component';
import { CrudConductorComponent } from './mant/mant-conductor/crud-conductor/crud-conductor.component';
import { CrudConductorListComponent } from './mant/mant-conductor/crud-conductor-list/crud-conductor-list.component';
import { DetalleConductorComponent } from './mant/mant-conductor/crud-conductor-list/detalle-conductor/detalle-conductor.component';
import { CrudTarifasComponent } from './mant/mant-tarifas/crud-tarifas/crud-tarifas.component';
import { CrudTarifasListComponent } from './mant/mant-tarifas/crud-tarifas-list/crud-tarifas-list.component';
import { DetalleTarifasComponent } from './mant/mant-tarifas/crud-tarifas-list/detalle-tarifas/detalle-tarifas.component';
import { HttpClientModule } from '@angular/common/http';
import { MantClienteComponent } from './mant/mant-cliente/mant-cliente.component';
import { CrudClienteComponent } from './mant/mant-cliente/crud-cliente/crud-cliente.component';
import { CrudClienteListComponent } from './mant/mant-cliente/crud-cliente-list/crud-cliente-list.component';
import { DetalleClienteComponent } from './mant/mant-cliente/crud-cliente-list/detalle-cliente/detalle-cliente.component';
import { MantPreferenciasComponent } from './mant/mant-preferencias/mant-preferencias.component';
import { CrudPreferenciasComponent } from './mant/mant-preferencias/crud-preferencias/crud-preferencias.component';
import { CrudPreferenciasListComponent } from './mant/mant-preferencias/crud-preferencias-list/crud-preferencias-list.component';
import { DetallePreferenciasComponent } from './mant/mant-preferencias/crud-preferencias-list/detalle-preferencias/detalle-preferencias.component';
import { MantHorariosComponent } from './mant/mant-horarios/mant-horarios.component';
import { CrudHorariosComponent } from './mant/mant-horarios/crud-horarios/crud-horarios.component';
import { CrudHorariosListComponent } from './mant/mant-horarios/crud-horarios-list/crud-horarios-list.component';
import { DetalleHorariosComponent } from './mant/mant-horarios/crud-horarios-list/detalle-horarios/detalle-horarios.component';
import { MantCompaniaComponent } from './mant/mant-compania/mant-compania.component';
import { CrudCompaniaComponent } from './mant/mant-compania/crud-compania/crud-compania.component';
import { CrudCompaniaListComponent } from './mant/mant-compania/crud-compania-list/crud-compania-list.component';
import { DetalleCompaniaComponent } from './mant/mant-compania/crud-compania-list/detalle-compania/detalle-compania.component';
import { MantTipodeservicioComponent } from './mant/mant-tipodeservicio/mant-tipodeservicio.component';
import { CrudTipodeservicioComponent } from './mant/mant-tipodeservicio/crud-tipodeservicio/crud-tipodeservicio.component';
import { CrudTipodeservicioListComponent } from './mant/mant-tipodeservicio/crud-tipodeservicio-list/crud-tipodeservicio-list.component';
import { DetalleTipodeservicioComponent } from './mant/mant-tipodeservicio/crud-tipodeservicio-list/detalle-tipodeservicio/detalle-tipodeservicio.component';
import { MantRutasComponent } from './mant/mant-rutas/mant-rutas.component';
import { CrudRutasComponent } from './mant/mant-rutas/crud-rutas/crud-rutas.component';
import { CrudRutasListComponent } from './mant/mant-rutas/crud-rutas-list/crud-rutas-list.component';
import { DetalleRutasComponent } from './mant/mant-rutas/crud-rutas-list/detalle-rutas/detalle-rutas.component';
import { MantEstadosComponent } from './mant/mant-estados/mant-estados.component';
import { CrudEstadosComponent } from './mant/mant-estados/crud-estados/crud-estados.component';
import { CrudEstadosListComponent } from './mant/mant-estados/crud-estados-list/crud-estados-list.component';
import { DetalleEstadosComponent } from './mant/mant-estados/crud-estados-list/detalle-estados/detalle-estados.component';
import { SempresasService } from './servicios/sempresas.service';
import { CheckboxModule } from 'primeng/primeng';
import {ListboxModule} from 'primeng/primeng';
import { ServicesComponent } from './services/services.component';
import { SerListComponent } from './services/ser-list/ser-list.component';
import { ListFechaComponent } from './services/ser-list/list-fecha/list-fecha.component';
import { ListClienteComponent } from './services/ser-list/list-cliente/list-cliente.component';
import { ListConductorComponent } from './services/ser-list/list-conductor/list-conductor.component';
import { AsignaTarifaComponent } from './services/ser-list/asigna-tarifa/asigna-tarifa.component';
import { AsignaConductorComponent } from './services/ser-list/asigna-conductor/asigna-conductor.component';
import { ListEstadoComponent } from './services/ser-list/list-estado/list-estado.component';
import { SerNewComponent } from './services/ser-new/ser-new.component';
import { SerUpComponent } from './services/ser-up/ser-up.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { CondPrefComponent } from './mant/mant-cliente/crud-cliente-list/cond-pref/cond-pref.component';
import { MantUsuariosComponent } from './mant/mant-usuarios/mant-usuarios.component';
import { NuevoUsuarioComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario.component';
import { ListaUsuariosComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios.component';
import { NuevoUsuarioClienteComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario-cliente/nuevo-usuario-cliente.component';
import { NuevoUsuarioConductorComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario-conductor/nuevo-usuario-conductor.component';
import { ListaUsuariosClientesComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-clientes/lista-usuarios-clientes.component';
import { ListaUsuariosConductoresComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-conductores/lista-usuarios-conductores.component';
import { NuevoUsuarioAdminComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario-admin/nuevo-usuario-admin.component';
import { ListaUsuariosAdminComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-admin/lista-usuarios-admin.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {InputMaskModule} from 'primeng/inputmask';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DetalleUsuariosAdminComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-admin/detalle-usuarios-admin/detalle-usuarios-admin.component';
import { VtcComponent } from './services/ser-list/vtc/vtc.component';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { EmpresasComponent } from './facturacion/empresas/empresas.component';
import { ConductoresComponent } from './facturacion/conductores/conductores.component';
import { ListEmpComponent } from './facturacion/list-emp/list-emp.component';
import {TableModule} from 'primeng/table';
import { PdfEmpresasComponent } from './facturacion/pdf-empresas/pdf-empresas.component';
import {DataViewModule} from 'primeng/dataview';
import { ListServiciosComponent } from './services/ser-list/list-cliente/list-servicios/list-servicios.component';
import {PanelModule} from 'primeng/panel';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import { ListServiciosCondComponent } from './services/ser-list/list-conductor/list-servicios-cond/list-servicios-cond.component';
import { ListServiciosEstComponent } from './services/ser-list/list-estado/list-servicios-est/list-servicios-est.component';
import { ListServiciosFechComponent } from './services/ser-list/list-fecha/list-servicios-fech/list-servicios-fech.component';
import { SerChgEstComponent } from './services/ser-chg-est/ser-chg-est.component';
import { ListCompComponent } from './facturacion/list-comp/list-comp.component';
import { PdfCompaniasComponent } from './facturacion/pdf-companias/pdf-companias.component';
import { ScheduleComponent } from './facturacion/schedule/schedule.component';
import { UrgentesComponent } from './urgentes/urgentes.component';
import { ActUrgComponent } from './services/act-urg/act-urg.component';
import { MantVehiculoComponent } from './mant/mant-vehiculo/mant-vehiculo.component';
import { CrudVehiculoComponent } from './mant/mant-vehiculo/crud-vehiculo/crud-vehiculo.component';
import { CrudVehiculoListComponent } from './mant/mant-vehiculo/crud-vehiculo-list/crud-vehiculo-list.component';
import { DetalleVehiculoComponent } from './mant/mant-vehiculo/crud-vehiculo-list/detalle-vehiculo/detalle-vehiculo.component';
import { CargasComponent } from './cargas/cargas.component';
import {GrowlModule} from 'primeng/growl';
import {Message} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import { MantCargasComponent } from './mant/mant-cargas/mant-cargas.component';
import { CrudCargasComponent } from './mant/mant-cargas/crud-cargas/crud-cargas.component';
import { CrudCargasListComponent } from './mant/mant-cargas/crud-cargas-list/crud-cargas-list.component';
import { DetalleCargasListComponent } from './mant/mant-cargas/crud-cargas-list/detalle-cargas-list/detalle-cargas-list.component';
import { MantIncidenciasComponent } from './mant/mant-incidencias/mant-incidencias.component';
import { CrudIncidenciasListComponent } from './mant/mant-incidencias/crud-incidencias-list/crud-incidencias-list.component';
import { DetalleIncidenciasListComponent } from './mant/mant-incidencias/crud-incidencias-list/detalle-incidencias-list/detalle-incidencias-list.component';
import { CrudIncidenciasComponent } from './mant/mant-incidencias/crud-incidencias/crud-incidencias.component';
import { ListTarfComponent } from './services/ser-list/list-tarf/list-tarf.component';
import {KeyFilterModule} from 'primeng/keyfilter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { MantCategoriasComponent } from './mant/mant-categorias/mant-categorias.component';
import { CrudCategoriasComponent } from './mant/mant-categorias/crud-categorias/crud-categorias.component';
import { CrudCategoriasListComponent } from './mant/mant-categorias/crud-categorias-list/crud-categorias-list.component';
import {PickListModule} from 'primeng/picklist';
import { DetalleCategoriasListComponent } from './mant/mant-categorias/crud-categorias-list/detalle-categorias-list/detalle-categorias-list.component';
import { ListSerTarComponent } from './services/ser-list/list-tarf/list-ser-tar/list-ser-tar.component';
import { SerComponent } from './mant/ser/ser.component';
import { EditComponent } from './mant/ser/edit/edit.component';
import { ConductorestComponent } from './facturacion/conductorest/conductorest.component';
import { EmpresastComponent } from './facturacion/empresast/empresast.component';
import { ListEmptComponent } from './facturacion/list-empt/list-empt.component';
import { ListComptComponent } from './facturacion/list-compt/list-compt.component';
import { SerUrgComponent } from './mant/ser/ser-urg/ser-urg.component';
import { ListPeriodoComponent } from './services/ser-list/list-periodo/list-periodo.component';
import { ListServiciosPerComponent } from './services/ser-list/list-periodo/list-servicios-per/list-servicios-per.component';
import { SerNewTafComponent } from './services/ser-new-taf/ser-new-taf.component';
import { HistCompComponent } from './facturacion/hist-comp/hist-comp.component';
import {TreeModule} from 'primeng/tree';
import { HistEmpComponent } from './facturacion/hist-emp/hist-emp.component';
import { AlertasComponent } from './alertas/alertas.component';
import { NewAleComponent } from './alertas/new-ale/new-ale.component';
import {AccordionModule} from 'primeng/accordion';
import { MantComercialComponent } from './mant/mant-comercial/mant-comercial.component';
import { MantGestorComponent } from './mant/mant-gestor/mant-gestor.component';
import { CrudComercialComponent } from './mant/mant-comercial/crud-comercial/crud-comercial.component';
import { CrudComercialListComponent } from './mant/mant-comercial/crud-comercial-list/crud-comercial-list.component';
import { DetalleComercialComponent } from './mant/mant-comercial/crud-comercial-list/detalle-comercial/detalle-comercial.component';
import { CrudGestorComponent } from './mant/mant-gestor/crud-gestor/crud-gestor.component';
import { CrudGestorListComponent } from './mant/mant-gestor/crud-gestor-list/crud-gestor-list.component';
import { DetalleGestorComponent } from './mant/mant-gestor/crud-gestor-list/detalle-gestor/detalle-gestor.component';
import { ListaUsuariosComercialesComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-comerciales/lista-usuarios-comerciales.component';
import { ListaUsuariosGestoresComponent } from './mant/mant-usuarios/lista-usuarios/lista-usuarios-gestores/lista-usuarios-gestores.component';
import { NuevoUsuarioComercialComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario-comercial/nuevo-usuario-comercial.component';
import { NuevoUsuarioGestorComponent } from './mant/mant-usuarios/nuevo-usuario/nuevo-usuario-gestor/nuevo-usuario-gestor.component';
import { ComercialesComponent } from './facturacion/comerciales/comerciales.component';
import { ComercialestComponent } from './facturacion/comercialest/comercialest.component';
import { HistComComponent } from './facturacion/hist-com/hist-com.component';
import { ListComComponent } from './facturacion/list-com/list-com.component';
import { ListComtComponent } from './facturacion/list-comt/list-comt.component';
import { PdfComercialesComponent } from './facturacion/pdf-comerciales/pdf-comerciales.component';
import { MapaComponent } from './services/mapa/mapa.component';
import { MantCoordinadorComponent } from './mant/mant-coordinador/mant-coordinador.component';
import { CrudCoordinadorComponent } from './mant/mant-coordinador/crud-coordinador/crud-coordinador.component';
import { CrudCoordinadorListComponent } from './mant/mant-coordinador/crud-coordinador-list/crud-coordinador-list.component';
import { DetalleCoordinadorComponent } from './mant/mant-coordinador/crud-coordinador-list/detalle-coordinador/detalle-coordinador.component';
import { EmpAFactComponent } from './facturacion/emp-a-fact/emp-a-fact.component';
import { ListEmpFacComponent } from './facturacion/emp-a-fact/list-emp-fac/list-emp-fac.component';

import { CmComponent } from './cm/cm.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SserService } from "./servicios/sser.service";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { RutaCondComponent } from './services/ruta-cond/ruta-cond.component';
import { MantCiudadComponent } from './mant/mant-ciudad/mant-ciudad.component';
import { CrudCiudadComponent } from './mant/mant-ciudad/crud-ciudad/crud-ciudad.component';
import { CrudCiudadListComponent } from './mant/mant-ciudad/crud-ciudad-list/crud-ciudad-list.component';
import { DetalleCiudadComponent } from './mant/mant-ciudad/crud-ciudad-list/detalle-ciudad/detalle-ciudad.component';
import { CronserComponent } from './cronser/cronser.component';
import { RecordarContraseniaComponent } from './recordar-contrasenia/recordar-contrasenia.component';
import { NuevaContraseniaComponent } from './nueva-contrasenia/nueva-contrasenia.component';
import { PlanHomeComponent } from './planificador/plan-home/plan-home.component';
import { BusUniComponent } from './services/bus-uni/bus-uni.component';
import { ResUniComponent } from './services/bus-uni/res-uni/res-uni.component';
import { PresupuestosComponent } from './facturacion/presupuestos/presupuestos.component';
import { NuevoPresComponent } from './facturacion/presupuestos/nuevo-pres/nuevo-pres.component';
import { BuscarPresComponent } from './facturacion/presupuestos/buscar-pres/buscar-pres.component';
import { ResPresComponent } from './facturacion/presupuestos/buscar-pres/res-pres/res-pres.component';
import { ExpedienteFacComponent } from './mant/mant-cliente/expediente-fac/expediente-fac.component';


// import { SerGridComponent } from './services/ser-grid/ser-grid.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'recordarContasenia', component: RecordarContraseniaComponent },
  { path: 'nuevaContasenia', component:NuevaContraseniaComponent },
  { path: 'home', component:  HomeComponent, canActivate: [ LogInGuard ]},
  { path: '',redirectTo: '/home', pathMatch:'full'},
  { path: 'edit/:idServicio', component:  EditComponent, canActivate: [ LogInGuard ]},
  { path: '**',redirectTo: '/home', pathMatch:'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    PlanificadorComponent,
    CrudEmpresaComponent,
    CrudEmpresaListComponent,
    MantEmpresaComponent,
    DetalleEmpresaComponent,
    LoginComponent,
    HomeComponent,
    CifradoComponent,
    MantConductorComponent,
    MantTarifasComponent,
    MantComponent,
    CrudConductorComponent,
    CrudConductorListComponent,
    DetalleConductorComponent,
    CrudTarifasComponent,
    CrudTarifasListComponent,
    DetalleTarifasComponent,
    MantClienteComponent,
    CrudClienteComponent,
    CrudClienteListComponent,
    DetalleClienteComponent,
    MantPreferenciasComponent,
    CrudPreferenciasComponent,
    CrudPreferenciasListComponent,
    DetallePreferenciasComponent,
    MantHorariosComponent,
    CrudHorariosComponent,
    CrudHorariosListComponent,
    DetalleHorariosComponent,
    MantCompaniaComponent,
    CrudCompaniaComponent,
    CrudCompaniaListComponent,
    DetalleCompaniaComponent,
    MantTipodeservicioComponent,
    CrudTipodeservicioComponent,
    CrudTipodeservicioListComponent,
    DetalleTipodeservicioComponent,
    MantRutasComponent,
    CrudRutasComponent,
    CrudRutasListComponent,
    DetalleRutasComponent,
    MantEstadosComponent,
    CrudEstadosComponent,
    CrudEstadosListComponent,
    DetalleEstadosComponent,
    ServicesComponent,
    SerListComponent,
    ListFechaComponent,
    ListClienteComponent,
    ListConductorComponent,
    AsignaTarifaComponent,
    AsignaConductorComponent,
    ListEstadoComponent,
    SerNewComponent,
    SerUpComponent,
    CondPrefComponent,
    MantUsuariosComponent,
    NuevoUsuarioComponent,
    ListaUsuariosComponent,
    NuevoUsuarioClienteComponent,
    NuevoUsuarioConductorComponent,
    ListaUsuariosClientesComponent,
    ListaUsuariosConductoresComponent,
    NuevoUsuarioAdminComponent,
    ListaUsuariosAdminComponent,
    DetalleUsuariosAdminComponent,
    VtcComponent,
    FacturacionComponent,
    EmpresasComponent,
    ConductoresComponent,
    ListEmpComponent,
    PdfEmpresasComponent,
    ListServiciosComponent,
    ListServiciosCondComponent,
    ListServiciosEstComponent,
    ListServiciosFechComponent,
    SerChgEstComponent,
    ListCompComponent,
    PdfCompaniasComponent,
    ScheduleComponent,
    UrgentesComponent,
    ActUrgComponent,
    MantVehiculoComponent,
    CrudVehiculoComponent,
    CrudVehiculoListComponent,
    DetalleVehiculoComponent,
    CargasComponent,
    MantCargasComponent,
    CrudCargasComponent,
    CrudCargasListComponent,
    DetalleCargasListComponent,
    MantIncidenciasComponent,
    CrudIncidenciasListComponent,
    DetalleIncidenciasListComponent,
    CrudIncidenciasComponent,
    ListTarfComponent,
    MantCategoriasComponent,
    CrudCategoriasComponent,
    CrudCategoriasListComponent,
    DetalleCategoriasListComponent,
    ListSerTarComponent,
    SerComponent,
    EditComponent,
    ConductorestComponent,
    EmpresastComponent,
    ListEmptComponent,
    ListComptComponent,
    SerUrgComponent,
    ListPeriodoComponent,
    ListServiciosPerComponent,
    SerNewTafComponent,
    HistCompComponent,
    HistEmpComponent,
    AlertasComponent,
    NewAleComponent,
    MantComercialComponent,
    MantGestorComponent,
    CrudComercialComponent,
    CrudComercialListComponent,
    DetalleComercialComponent,
    CrudGestorComponent,
    CrudGestorListComponent,
    DetalleGestorComponent,
    ListaUsuariosComercialesComponent,
    ListaUsuariosGestoresComponent,
    NuevoUsuarioComercialComponent,
    NuevoUsuarioGestorComponent,
    ComercialesComponent,
    ComercialestComponent,
    HistComComponent,
    ListComComponent,
    ListComtComponent,
    PdfComercialesComponent,
    MapaComponent,
    MantCoordinadorComponent,
    CrudCoordinadorComponent,
    CrudCoordinadorListComponent,
    DetalleCoordinadorComponent,
    EmpAFactComponent,
    ListEmpFacComponent,
    CmComponent,
    RutaCondComponent,
    MantCiudadComponent,
    CrudCiudadComponent,
    CrudCiudadListComponent,
    DetalleCiudadComponent,
    CronserComponent,
    RecordarContraseniaComponent,
    NuevaContraseniaComponent,
    PlanHomeComponent,
    BusUniComponent,
    ResUniComponent,
    PresupuestosComponent,
    NuevoPresComponent,
    BuscarPresComponent,
    ResPresComponent,
    ExpedienteFacComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD4ZEK6-JONSttoEjfuzRIArHHPHlmQ27E',
      language: 'es',
      libraries: ['geometry', 'places']
    }),
    BrowserModule,
    ConfirmDialogModule,
    KeyFilterModule,
    PickListModule,
    GrowlModule,
    MessagesModule,
    AccordionModule,
    SidebarModule,
    ToggleButtonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
   /*  HttpModule, */
    RouterModule.forRoot(routes),
    FormsModule,
    TreeModule,
    CalendarModule,
    ColorPickerModule,
    CheckboxModule,
    ListboxModule,
    HttpClientModule,
    ScheduleModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    InputMaskModule,
    InputSwitchModule,
    DataViewModule,
    PanelModule,
    DialogModule,
    DropdownModule,
    TableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    SempresasService,
    // ConfirmationService,
    UsuariosService,
    AUTH_PROVIDERS,
    ConfirmationService,
    LogInGuard,
    SserService,
    SwPush,
    SwUpdate
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
