import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { StarifasService } from '../../../servicios/starifas.service';
import { Estados } from '../../../comun/estados';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-crud-estados-list',
  templateUrl: './crud-estados-list.component.html',
  styleUrls: ['./crud-estados-list.component.css'],
  providers: [StarifasService]
})
export class CrudEstadosListComponent implements OnInit {

  @Input() estadoListVis: boolean;
  @Input() detalleEstadovisible: boolean;
  @Output() cerrarEstadoListEvento = new EventEmitter();
  @Input() estadoVis: boolean;
  @Output() cerrarEstadoEvento = new EventEmitter();
  estados: Estados[];

  message: string[];
  msg: string;

  constructor(private starifas: StarifasService) {
    this.message = [];
  }

  ngOnInit() {
    this.estadoListVis = false;
    this.detalleEstadovisible = false;
    this.getEstados();
  }

  getEstados() {
    this.starifas.getEstados().subscribe(result => {
      this.estados = result;
    });
  }
  retCore(core:number):boolean{
      if (core==1) return false;
      else return true;
  }
  actualizarLista() {
    // if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>');
    this.ngOnInit();
  }
}
