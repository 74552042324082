import { Component, OnInit } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { Conductor} from '../../comun/conductor';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
// import {HttpModule} from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { Propietario } from '../../comun/propietario';
import { Compania } from '../../comun/compania';

@Component({
  selector: 'app-conductorest',
  templateUrl: './conductorest.component.html',
  styleUrls: ['./conductorest.component.css'],
  providers: [SserService, SconductoresService, SfactService]
})
export class ConductorestComponent implements OnInit {
  @Input() facConTVis: boolean;
  @Output() cerrarfacConTEvento = new EventEmitter();

  conductores: Conductor[];
  companias: Compania[];

  fechaIni: string;
  fechaFin: string;
  fechaUltFact: string;
  companiaFact: Compania;
  propietario: Propietario;
  message: string[];
  msgs = [];
  aleVis= false;
  
  constructor(private sser: SserService, private sconductores: SconductoresService, private sfact: SfactService) { }


  ngOnInit() {
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.getPropietario();
  }
  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.aleVis = !this.aleVis;
  }
  actualizaUM(){
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaU3M(){
    this.fechaIni = moment().startOf('month').subtract(2, 'month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaU6M(){
    this.fechaIni = moment().startOf('month').subtract(5, 'month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }

  getPropietario () {
    let  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
         break;
      }
    });
  }
}
