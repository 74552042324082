import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-cliente',
  templateUrl: './mant-cliente.component.html',
  styleUrls: ['./mant-cliente.component.css']
})
export class MantClienteComponent implements OnInit {
  @Input() mantClientesVis: boolean;
  @Output() cerrarMantClientesEvento = new EventEmitter();
  clienteListVis: boolean;
  clienteVis: boolean;
  expedienteListVis: boolean;

  constructor() { }

  ngOnInit() {
    this.mantClientesVis = false;
    this.clienteVis = false;
    this.clienteListVis = false;
    this.expedienteListVis = false;
  }

}
