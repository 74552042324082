import { Component, OnInit } from '@angular/core';

import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Estados } from '../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.css']
})
export class FacturacionComponent implements OnInit {
  facEmpVis:boolean;
  facConVis:boolean;
  facConTVis:any;
  facEmpTVis:any;
  @Input() factVis: boolean;
  hisEmpTVis:any;
  hisConTVis:any;
  facComVis:any;
  hisComTVis:any;
  facComTVis:any;
  facEmpFechVis:any;
  presbut:boolean = false;
  fase:any;
  nueDis:boolean = false;
  
  
  
  constructor() { }

  ngOnInit() {
    this.funcRes();
  }
  funcRes(){
    let idCiudad = localStorage.getItem("IdCiudad");
    if(idCiudad && idCiudad=='0'){
      console.log('oooo----------------->>>>>>  Funcionalidad restringida');
      this.nueDis = true;
    }else{
      this.nueDis = false;

    }
  }

}
