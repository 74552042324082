export class Global {
  public readonly SERVIDOR_URL: string =  'https://luxucar.es';;//'https://golddriving.es';//// 'http://ovetonauta.es'
  public readonly SERVIDOR_ADM_URL: string = 'https://admin.luxucar.es';//'https://admin.ovetonauta.es';//'http://localhost:1200';//'https://admin.luxucar.es';//'https://admin.golddriving.es/'; //'http://localhost:1111';'http://vtc.ovetonauta.es'//

  public readonly ADM_SCRIPTS = "/scripts";
  public readonly CLI_SCRIPTS = "/scriptsCli";
  public readonly CON_SCRIPTS = "/scriptsCon";

  public readonly FAC_ENPRESAS_URL: string = '../facEmpresas/';
  public readonly PRES_ENPRESAS_URL: string = '/presEmpresas/';
  public readonly FAC_COMPANIAS_URL: string = '../facCompanias/';
  public readonly FAC_COMERCIAL_URL: string = '../facComerciales/'; 

  public readonly RECUPERAR_CONTRASENIA_URL: string = '/nuevaContasenia?tkn={';
  
  public readonly ESTADO_EN_PETICION: string = 'En petición';
  public readonly ESTADO_ASIGNADO: string = 'Asignado';
  public readonly ESTADO_EN_CAMINO: string = 'En camino';
  public readonly ESTADO_FINALIZADO: string = 'Finalizado';
  public readonly ESTADO_ANULADO: string = 'Anulado';
  public readonly ESTADO_ADMINISTRANDO: string = 'Administrando';
  public readonly ESTADO_EN_RUTA: string = 'En ruta';
  public readonly ESTADO_RESERVADO: string = 'Reservado';
  public readonly ESTADO_EN_POSICION: string = 'En posición';

  public readonly TIEMPO_ESPERA_NOTIFI_SEG: number = 60;
  public readonly TIEMPO_ESPERA_ALERTAS_SEG: number = 15;
  public readonly NUMERO_REPETICIONES_NOTIFI: number = 3;

  public readonly LOG_SER:boolean= true;

  public readonly TARIFA_DEF_ADM: string = '9999';
  public readonly TARIFA_NOM_ADM: string = 'Tarifa promoción';

  public readonly SERVIDOR_URL_IMG: string = this.SERVIDOR_URL+this.ADM_SCRIPTS+"/s_comun/p.php";

  public readonly DIRBASE_IMG: string = "/images/";
  public readonly DIRBASE_IMG_COND: string = "/imag_cond/";
  public readonly COND_LOG_NAME="logo";
  public readonly FILAS_POR_PAGINA       = 10;
  public readonly TIPO_LOCAL_TXT_NUM: string = "PresLuxucar";

}
