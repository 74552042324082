import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Preferencia } from '../../../../comun/preferencia';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PreferenciasPeticion } from '../../../../comun/preferencias-peticion';

@Component({
  selector: 'app-detalle-preferencias',
  templateUrl: './detalle-preferencias.component.html',
  styleUrls: ['./detalle-preferencias.component.css'],
  providers: [SempresasService]
})
export class DetallePreferenciasComponent implements OnInit {

  @Input() preferencia: Preferencia;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarPreferenciaListEvento = new EventEmitter();

  detallePreferenciavisible: boolean;
  nuevaPreferencia: Preferencia;
  estados: Estados[];


  constructor(private sempresas: SempresasService) { }

  ngOnInit() {
    this.nuevaPreferencia = new Preferencia();
    Object.assign(this.nuevaPreferencia, this.preferencia);
    this.getEstadosbd();
  }
  actualizaPreferencia() {
    this.nuevaPreferencia.visible = false;
    this.preferencia = this.nuevaPreferencia;
    //if(localStorage.getItem('debug')==='1') console.log('--->>>' + JSON.stringify(this.nuevaPreferencia));
     this.sempresas.updatePreferencia(this.nuevaPreferencia).subscribe();
     this.cerrarDetalleVisEvento.emit();
     this.actualizarPreferenciaListEvento.emit();
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.nuevaPreferencia.estado = estado;
    if (estado === 'inactivo') {
      this.nuevaPreferencia.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.nuevaPreferencia.fechaBaja = '0000-00-00';
      this.nuevaPreferencia.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
}
