import { Component, OnInit } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { StarifasService } from '../../servicios/starifas.service';
import { SempresasService } from '../../servicios/sempresas.service';
// import {HttpModule} from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

import { Servicio } from '../../comun/servicio';
import { Empresas } from '../../comun/empresas';
import { Global } from '../../comun/global';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';

@Component({
  selector: 'app-ser-chg-est',
  templateUrl: './ser-chg-est.component.html',
  styleUrls: ['./ser-chg-est.component.css'],
  providers: [SserService, StarifasService]
})
export class SerChgEstComponent implements OnInit {
  @Input() servicio: Servicio;
  @Output() cerrarSerUpEvento = new EventEmitter();
  @Output() cerrarSerChgEstEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();
  estados: Estados[];
  estadoReciente: string;
  estadoActual: string;
  global:Global = new Global();


 
  constructor(private sser: SserService, private sempresas: StarifasService) { }

  ngOnInit() {
    this.getEstados();
    this.estadoActual = this.servicio.estado;
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  up() {
    if (this.estadoReciente !== this.servicio.estado ) {
      this.servicio.estado = this.estadoReciente;
      if(this.servicio.estado == this.global.ESTADO_FINALIZADO) this.servicio.tiempoFinRuta=moment().format('YYYY-MM-DD HH:mm');
      if(this.servicio.estado == this.global.ESTADO_EN_RUTA) this.servicio.tiempoInicioRuta=moment().format('YYYY-MM-DD HH:mm');
      if(this.servicio.estado == this.global.ESTADO_ANULADO) this.servicio.tiempoFinRuta=moment().format('YYYY-MM-DD HH:mm');
      this.servicio = JSON.parse(JSON.stringify( this.servicio, this.replacer));
      this.sser.setServicio(this.servicio).subscribe();
      this.cerrarSerChgEstEvento.emit();
      // this.cerrarSerUpEvento.emit();
      this.servicio.visible2=false;
    }
  }
  getEstados() {
    this.sempresas.getEstados().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.estadoReciente = estado;
  }
}