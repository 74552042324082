import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
// import {HttpModule} from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { Propietario } from '../../comun/propietario';

@Component({
  selector: 'app-empresast',
  templateUrl: './empresast.component.html',
  styleUrls: ['./empresast.component.css'],
  providers: [SserService, SempresasService, SfactService]
})
export class EmpresastComponent implements OnInit {
  @Input() facEmpTVis: boolean;
  @Output() cerrarfacEmpTEvento = new EventEmitter();


  empresas: Empresas[];
  clientes: Cliente[];

  fechaIni: string;
  fechaFin: string;
  fechaUltFact: string;
  empresaFact: Empresas;
  propietario: Propietario;
  message: string[];
  msgs = [];
  aleVis= false;

/* tslint:disable:triple-equals */
  constructor(private sser: SserService, private sempresas: SempresasService, private sfact: SfactService) { }

  ngOnInit() {

    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.getPropietario();

  }

  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.aleVis = !this.aleVis;
  }

  actualizaUM(){
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaU3M(){
    this.fechaIni = moment().startOf('month').subtract(2, 'month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaU6M(){
    this.fechaIni = moment().startOf('month').subtract(5, 'month').format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }

  getPropietario () {
    let  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
         break;
      }
    });
  }

}
