import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Tarifa } from '../../../comun/tarifa';
import { Conductor } from '../../../comun/conductor';
import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Ruta } from '../../../comun/ruta';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import { Ciudad } from '../../../comun/ciudad';


@Component({
  selector: 'app-crud-tarifas-list',
  templateUrl: './crud-tarifas-list.component.html',
  styleUrls: ['./crud-tarifas-list.component.css'],
  providers: [SempresasService, StarifasService, SconductoresService]
})
export class CrudTarifasListComponent implements OnInit {
  @Input() tarifasListVis: boolean;
  @Input() detalleTarifasvisible: boolean;
  @Output() cerrarTarifasListEvento = new EventEmitter();
  tarifasVis: boolean;
  detalleVis: boolean;
  tarifas: Tarifa[];
  empresas: Empresas[];
  rutas: Ruta[];
  message: string[];
  ciudades: Ciudad[] = [];
  idCiudad:string="";
  tipoDeServicios: TipoDeServicios[];

  constructor(private sempresas: SempresasService, private starifas: StarifasService, private sconductores: SconductoresService) {
    this.message = [];
  }
  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad"); 
    this.tarifasListVis = false;
    this.detalleTarifasvisible = false;

    this.tarifasVis = true;
    this.detalleVis = false;
    this.tarifasListVis = false;
    this.detalleTarifasvisible = false;
    this.getEmpresas();
    this.getCiudades();
    this.getRutas();
    this.getTarifas();
    this.getTipoDeServicios();
  }

  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getCiudad(idCiudad,ruta): string {
    
    let res = '';
    let c = this.ciudades.filter(item => item.id == idCiudad);
    if (c && c.length > 0) {
      res = c[0].nombre;
      
    }
    return res;
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      this.empresas.unshift({nombre: 'todos', id: '1', cif: '', direccion: '', telefono: '', contactop: ''
                             , mailp: '', visible: false, estado: '', fechaAlta: '', fechaBaja: '',nombreComercial:'', telefonoTrafico:''});
    });
  }
  getRutas() {
    this.starifas.getRutas(null).subscribe(result => {
      this.rutas = result;
    });
  }

  getTarifas() {

    let cond = JSON.stringify({ 'idCiudad': this.idCiudad });
    
    this.starifas.getTarifas(cond).subscribe(result => {
      this.tarifas = result;
    });
  }
  getTarifasEmpresas(empresaId: string) {
    //console.log('--------------------->>>>>>>>>>>cargo tarif');
    this.starifas.getTarifasEmpresas(empresaId, null,this.idCiudad).subscribe(result => {
      this.tarifas = result;
      for(let tar of this.tarifas){
        if(tar.tarifaConductorRed){
          console.log('tar.tarifaConductor = '+tar.tarifaConductor);
          console.log('tar.tarifaConductorRed = '+tar.tarifaConductorRed);
        }
      }
    });
  }

  borraTarifa(id: string) {
    this.message[id] = '¡¡Atencion, ha inactivado la tarifa!!';
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 2500);
    this.starifas.borraTarifa(id).subscribe();
    this.actualizarLista();

  }
  actualizaLista(id: any) {
    if ( id === '1') {
      this.getTarifas();
    }else {
      //if(localStorage.getItem('debug')==='1') console.log('id--->>>>' + id);
      this.getTarifasEmpresas(id);
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
  estaActiva(estado):boolean{
    let res=false;
    estado=='activo'?res=true:res=false;
    return res;
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  TextTipoDeServicio(id:string){

    if(this.tipoDeServicios){
      let a = this.tipoDeServicios.filter(item => item.id == id)
      if (a && a.length > 0) {
        return a[0].nombre;          
      }
    }
  }
}
