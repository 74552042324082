import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
// import {HttpModule} from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { Servicio } from '../../../comun/servicio';
import { Compania } from '../../../comun/compania';
import { Empresas } from '../../../comun/empresas';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Ciudad } from '../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-crud-compania',
  templateUrl: './crud-compania.component.html',
  styleUrls: ['./crud-compania.component.css'],
  providers: [SconductoresService,StarifasService,ConfirmationService]
})
export class CrudCompaniaComponent implements OnInit {

  @Input() companiaVis: boolean;
  @Output() cerrarCompaniaEvento = new EventEmitter();
  ok:any;

  companiaNueva: Compania = new Compania();

  ciudades: Ciudad[] = [];
  selectedCiudades: Ciudad[] = [];

  constructor(private sconductores: SconductoresService,private staf: StarifasService,  private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.companiaVis = false;
    this.getCiudades();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  new() {
    this.companiaNueva.estado = 'activo';
    this.companiaNueva.fechaAlta = moment().format('YYYY-MM-DD');
    this.companiaNueva.fechaBaja = '0000-00-00';
    this.companiaNueva.visible = false;
  
    this.sconductores.setCompania(this.companiaNueva).subscribe(result=>{
            
      let msg ="Se ha creado la compañia";
      
      if (result.error){
        msg = "No se ha creador la compañia debido al siguiente error: "+result.error;
      }else if (result.id){
        
        if (this.selectedCiudades.length > 0) { 
          let aa = "[";
          for (let cs of this.selectedCiudades) {
             aa += "{\"idCompania\": \""+result.id+"\", \"idCiudad\": \""+cs.id+"\"},";
          }
          aa = aa.substring(0,aa.length-1)+"]";
          if(localStorage.getItem('debug')==='1') console.log("::::::::::::::::::::::::::::>>>>>>"+aa);
          this.sconductores.setComp2ciu(aa).subscribe();

        }

      }

      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.cerrarCompaniaEvento.emit();
        }
      });
 
    });    
  }
}
